import Modal from "react-bootstrap/Modal";
import {Textarea, Title} from "@mantine/core";
import React, {useContext, useEffect, useRef} from "react";
import {LanguageContext} from "../../context/LanguageContext";
import {TRANSLATION_KEYS} from "../../util/constants";
import Button from "../base/Button/Button";

function ModalInput({label, data, setData, readOnly = false, onClose, centered = false}) {
    const {t} = useContext(LanguageContext);
    const ref = useRef(null);

    useEffect(() => {
        ref?.current.focus();
    }, []);

    return (
        <Modal size="lg" show={true} onHide={onClose} backdrop="static" keyboard={false} centered={centered} nested={1}>
            <Modal.Header>
                <Title order={4}>{label}</Title>
            </Modal.Header>
            <Modal.Body>
                <Textarea ref={ref} rows={8} value={data} onChange={(e) => setData(e.target.value)} disabled={readOnly}
                          autoFocus/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose()}>
                    {t(TRANSLATION_KEYS.CLOSE)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalInput;