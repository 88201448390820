import React from "react";
import {DatePickerInput as MantineDatePickerInput} from "@mantine/dates";

/**
 * @description Wrapper custom component for Mantine DatePickerInput
 * @param size - Size of the input | default: xs | xs | sm | md | lg | xl | xxl
 * @param valueFormat - Date format | default: DD/MM/YYYY
 * @param value - Date value
 * @param clearable - indicates if the input should be clearable
 * @param useCustomFormatter - allow the input to parse and render a date in "yyyy-mm-dd" format in the label
 * @param props
 */
function DatePickerInput({size = "xs", valueFormat = "DD/MM/YYYY", value, clearable = true, useCustomFormatter = false, ...props}) {
    function useValue() {
        if (useCustomFormatter) {
            if (value instanceof Date) {
                return value
            } else {
                const [year, month, day] = value.split("-")

                const formattedValue = new Date(year, month - 1, day)
                return formattedValue
            }
        } else {
            if (value instanceof Date) {
                return value
            } else if (value) {
                return new Date(value)
            } else {
                return null
            }
        }
    }

    return (
        <MantineDatePickerInput
            size={size}
            clearable
            popoverProps={{zIndex: 10000}}
            value={useValue()}
            valueFormat={valueFormat}
            {...props}
        />
    )
}

export default DatePickerInput;
