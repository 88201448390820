import React from "react";
import {TextInput as MantineTextInput} from "@mantine/core";
import Text from "../Text/Text";
import styles from "./TextInput.module.css";

/**
 * @description Wrapper custom component for Mantine TextInput
 * @param size - Size of the text input | default: xs | xs | sm | md | lg | xl | xxl
 * @param rightSectionText - Text to be displayed in the right section of the text input | default: ""
 * @param rightSectionTextSize - Size of the text in the right section | default: xs | xs | sm | md | lg | xl | xxl
 * @param rightSectionTextPadding - Padding of the text in the right section | default: 4
 */
export default function TextInput({size = "xs", rightSectionText = "", rightSectionTextSize = "xs", rightSectionWidth = null, ...props}) {
    return (
        <MantineTextInput classNames={styles} size={size} {...props}
                          rightSectionWidth={rightSectionWidth}
                          rightSection={rightSectionText === "" ?
                              null
                              :
                              <Text cVariant={"surface-2"} size={rightSectionTextSize}>{rightSectionText}</Text>
                          }/>
    );
}