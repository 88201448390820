import TextInput from "../base/TextInput/TextInput";
import CountryTable from "../CountryTable";
import React from "react";
import controller from "../../controller/controller";
import {notifications} from "@mantine/notifications";
import Text from "../base/Text/Text";
import Container from "../base/Container/Container";

/**
 * @param country {Object} - The country state object
 * @param setCountry {Function} - The country setter state function
 * @param showTable {Boolean} - Whether to show the table or not | default: true
 * @param inputDisabled {Boolean} - Whether the input is disabled or not | default: false
 */

export default function CountryAsyncInput({
                                              country,
                                              setCountry,
                                              showTable = true,
                                              inputDisabled = false
                                          }) {
    function handleChange(e) {
        const {name, value} = e.target;
        setCountry((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    async function handleEventChange(event) {
        /* allow the user to clear the fields if input was empty and an event is triggered */
        if (country?.code === "") {
            setCountry({
                code: "",
                name: "",
            });
            return;
        }

        if (event.key === "Tab" || event.key === "Enter" || event.type === "blur") {
            try {
                const data = await controller.getCountries({code: event.target.value});

                if (data.length === 0) {
                    setCountry({code: "", name: "INVALIDO"});
                } else {
                    setCountry({code: data[0].code, name: data[0].name});
                }
            } catch (error) {
                notifications.show({
                    title: "Error!",
                    message: "Error al buscar país.",
                    color: "red",
                });
            }
        }
    }

    return (
        <Container styleVariant={"unstyled"} direction={"column"} justify={"space-between"}>
            <div>
                <Text lh={1.55} mb={1} w={"100%"} cVariant={"primary"}>País</Text>
            </div>
            <Container styleVariant={"unstyled"} direction={"row"} justify={"space-between"} align={"center"}
                       columnGap={4}>
                <TextInput name={"code"} value={country?.code}
                           onChange={handleChange}
                           onKeyDown={handleEventChange}
                           onBlur={handleEventChange}
                           style={{width: "25%"}}
                           disabled={inputDisabled}
                />
                <TextInput value={country?.name} disabled style={{width: "70%"}}/>
                {showTable &&
                    <CountryTable setCountry={setCountry}/>
                }
            </Container>
        </Container>
    )
}