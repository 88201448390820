export const BASE_URL = 'https://s1629.nty.uy:8889'; //PROD
//export const BASE_URL = 'https://s1629.nty.uy:10889'; //TEST
//export const BASE_URL = 'http://localhost:8889'; //TEST LOCALHOST


export const PERMISSION = {
    ALTA_COTIZACION: "ALTA_COTIZACION",
    ALTA_ORGANIZACION: "ALTA_ORGANIZACION",
    ALTA_USUARIO: "ALTA_USUARIO",
    BAJA_USUARIO: "BAJA_USUARIO",
    CONSULTAR_AGENTE: "CONSULTAR_AGENTE",
    CONSULTAR_CLIENTE: "CONSULTAR_CLIENTE",
    CONSULTAR_COMMODITY: "CONSULTAR_COMMODITY",
    CONSULTAR_COTIZACION: "CONSULTAR_COTIZACION",
    CONSULTAR_COUNTRY: "CONSULTAR_COUNTRY",
    CONSULTAR_ORGANIZACION: "CONSULTAR_ORGANIZACION",
    CONSULTAR_UNLOCO: "CONSULTAR_UNLOCO",
    CONSULTAR_USUARIO: "CONSULTAR_USUARIO",
    CONSULTAR_SPOT: "CONSULTAR_SPOT",
    MODIFICAR_COTIZACION_COSTOS: "MODIFICAR_COTIZACION_COSTOS",
    MODIFICAR_COTIZACION_VENTAS: "MODIFICAR_COTIZACION_VENTAS",
    MODIFICAR_ORGANIZACION: "MODIFICAR_ORGANIZACION",
    MODIFICAR_SPOT: "MODIFICAR_SPOT",
    MODIFICAR_TARIFA_EXPRESS: "MODIFICAR_TARIFA_EXPRESS",
    MODIFICAR_USUARIO: "MODIFICAR_USUARIO",
    SOLICITAR_COTIZACION: "SOLICITAR_COTIZACION",
    PRICING: "PRICING",
    COMERCIAL: "COMERCIAL",
    GERENTE_COMERCIAL: "GERENTE_COMERCIAL",
    ALTA_CHARGE_CODE: "ALTA_CHARGE_CODE",
    CONSULTAR_CHARGE_CODE: "CONSULTAR_CHARGE_CODE",
    BAJA_CHARGE_CODE: "BAJA_CHARGE_CODE",
    BAJA_COTIZACION: "BAJA_COTIZACION",
    CONSULTAR_SHIPMENT: "CONSULTAR_SHIPMENT",
    MODIFICAR_SHIPMENT: "MODIFICAR_SHIPMENT",
    SOLICITAR_SHIPMENT: "SOLICITAR_SHIPMENT",
    EXPRESS_OP: "EXPRESS_OP",
    CONSULTAR_STATS: "CONSULTAR_STATS",
    PRICING_RULES: "PRICING_RULES",
};

export const ROLES = {
    COMMERCIAL: "COMMERCIAL",
    PRICING_AIR: "PRICING_AIR",
    PRICING_SEA: "PRICING_SEA",
    PRICING_ROAD: "PRICING_ROAD",
    OPERATIONS_AIR: "OPERATIONS_AIR",
    OPERATIONS_SEA: "OPERATIONS_SEA",
    OPERATIONS_ROAD: "OPERATIONS_ROAD",
    ADMINISTRATIVE: "ADMINISTRATIVE",
    ADMIN: "ADMIN",
};

export const COLORS = {
    PRIMARY: "#f68d2e",
    SECONDARY: "#212529",
    TERTIARY: "#babec2",
    GRAY_100: "#565656",
}

export const TRUE_FALSE_OPTIONS = [
    {value: 'true', label: 'SI'},
    {value: 'false', label: 'NO'},
];

export const CURRENCIES_OPTIONS = [
    {value: 'USD', label: 'USD'},
    {value: 'UYU', label: 'UYU'},
    {value: 'EUR', label: 'EUR'},
];

export const IVA = {
    percentage: 22,
    multiplier: 0.22,
}

export const QUOTATION_DOCUMENT_TYPES = [
    {value: "PKL", label: "PKL"},
    {value: "CIV", label: "CIV"},
    {value: "MSDS", label: "MSDS"},
    {value: "MSC", label: "OTROS"},
]

export const SHIPMENT_DOCUMENT_TYPES = [
    ...QUOTATION_DOCUMENT_TYPES,
    {value: "FCO", label: "FCO"},
    {value: "AWB", label: "AWB"},
]

export const INCOTERM_OPTIONS = [
    {value: "EXW", label: "EXW"},
    {value: "FCA", label: "FCA"},
    {value: "FAS", label: "FAS"},
    {value: "FOB", label: "FOB"},
    {value: "CFR", label: "CFR"},
    {value: "CIF", label: "CIF"},
    {value: "CIP", label: "CIP"},
    {value: "CPT", label: "CPT"},
    {value: "DAP", label: "DAP"},
    {value: "DPU", label: "DPU"},
    {value: "DDP", label: "DDP"},
]

export const TRANSPORT_OPTIONS = {
    SEA: [
        {value: "FCL", label: "Maritimo - FCL"},
        {value: "LCL", label: "Maritimo - LCL"},
        {value: "RORO", label: "Maritimo - RORO"},
    ],
    AIR: [
        {value: "ULD", label: "Aéreo - ULD"},
        {value: "LSE", label: "Aéreo - LSE"},
    ],
    ROAD: [
        {value: "FTL", label: "Terrestre - FTL"},
        {value: "LTL", label: "Terrestre - LTL"},
    ],
}

export const QUOTE_STATUS = {
    REQUESTED: "REQUESTED",
    PENDING: "PENDING",
    QUOTED: "QUOTED",
    UPDATED: "UPDATED",
    SENT: "SENT",
    APPROVED: "APPROVED",
    NOT_APPROVED: "NOT_APPROVED",
    FUTURE: "FUTURE",
}

export const EXPRESS_QUOTE_STATUS = {
    NOT_REQUESTED: "NOT_REQUESTED",
    REQUESTED: "REQUESTED",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
}

export const INFO = ["description", "validez", "ruta", "ttime", "carrier", "freeDays"];

export const TRANSLATION_KEYS = {
    TRANSPORT: 'transport',
    MODE: 'mode',
    CONTAINERS: 'containers',
    TRUCKS: 'trucks',
    ORIGIN: 'origin',
    DESTINATION: 'destination',
    INCOTERM: 'incoterm',
    PACKING: 'packing',
    PICKUP: 'pickups',
    COMMODITY: 'commodity',
    GOODS_VALUE: 'goodsValue',
    INSURANCE_VALUE: 'insuranceValue',
    TOTAL_WEIGHT: 'totalWeight',
    TOTAL_VOLUME: 'totalVolume',
    TOTAL_CHARGEABLE: 'totalChargeable',
    TOTAL_CARTONS: 'totalCartons',
    TOTAL_PALLETS: 'totalPallets',
    STACKABLE: 'stackable',
    VIEW_DOCS: 'viewDocs',
    VIEW_NOTES: 'viewNotes',
    DOCUMENTS: 'documents',
    NOTES: 'notes',
    CODE: 'code',
    CONCEPT: 'concept',
    CONCEPT_EDITABLE: 'conceptEditable',
    TOTAL: 'total',
    PROVIDER_REFERENCE: 'providerReference',
    TOOLTIP_PROVIDER_REFERENCE: 'tooltipProviderReference',
    DESCRIPTION: 'description',
    VALIDITY: 'validity',
    ROUTE: 'route',
    TRANSIT_TIME: 'transitTime',
    CARRIER: 'carrier',
    FREE_DAYS: 'freeDays',
    COMMENTS: 'comments',
    COMMENTS_AND_CONDITIONS: 'commentsAndConditions',
    COST: 'cost',
    QUANTITY: 'quantity',
    WEIGHT: 'weight',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    ADD_NEW_COST: 'addNewCost',
    ERROR_ALERT: 'errorAlert',
    ERROR_WHILE_GETTING_QUOTATION: 'errorWhileGettingQuotation',
    ERROR_CANNOT_ADD_MORE_CONCEPTS: 'errorCannotAddMoreConcepts',
    ERROR_AT_LEAST_ONE_COST: 'errorAtLeastOneCost',
    ERROR_AT_LEAST_ONE_CHARGE: 'errorAtLeastOneCharge',
    ERROR_AT_LEAST_ONE_VALUE: 'errorAtLeastOneValue',
    ERROR_EVERY_CHARGE_NEEDS_A_CODE: 'errorEveryChargeNeedsACode',
    ERROR_WRONG_DATE_FORMAT: 'errorWrongDateFormat',
    ERROR_SENDING_COSTS: 'errorSendingCosts',
    SEND_COSTS: 'sendCosts',
    SEND_COSTS_CONFIRMATION: 'sendCostsConfirmation',
    SEND_COSTS_SUCCESS: 'sendCostsSuccess',
    SUCCESS_ALERT: 'successAlert',
    TOOLTIP_COST_PER_KG: 'tooltipCostPerKg',
    TOOLTIP_DELETE_CHARGE: 'tooltipDeleteCharge',
    TOOLTIP_DELETE_COST: 'tooltipDeleteCost',
    TOOLTIP_ADD_CHARGE: 'tooltipAddCharge',
    TOOLTIP_ADD_COST: 'tooltipAddCost',
    TOOLTIP_DUPLICATE_COST: 'tooltipDuplicateCost',
    COST_ALREADY_QUOTED: 'costAlreadyQuoted',
    QUOTATION_HAS_BEEN_CANCELED: 'quotationHasBeenCanceled',
    CLOSE: 'close',
    YES: 'yes',
    NO: 'no',
};

export const SUPPORTED_LANGUAGES = {
    SPANISH: 'es',
    ENGLISH: 'en',
    PORTUGUESE: 'pt',
};

export const SUPPORTED_LANGUAGES_OPTIONS = [
    {value: SUPPORTED_LANGUAGES.SPANISH, label: "Español"},
    {value: SUPPORTED_LANGUAGES.ENGLISH, label: "English"},
    {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: "Português"},
];

export const CONDITIONS = {
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    CONTAINS: 'CONTAINS',
    HAS: 'HAS',
    NOT_HAS: 'NOT_HAS',
};

export const CONDITIONS_OPTIONS = [
    {value: CONDITIONS.EQUALS, label: 'Igual a'},
    {value: CONDITIONS.NOT_EQUALS, label: 'Distinto de'},
    {value: CONDITIONS.CONTAINS, label: 'Contiene'},
    {value: CONDITIONS.HAS, label: 'Tiene'},
    {value: CONDITIONS.NOT_HAS, label: 'No tiene'},
];

export const FILTER_TYPES = {
    INCOTERM: 'INCOTERM',
    ORIGIN: 'ORIGIN',
    DESTINATION: 'DESTINATION',
    AUTORATE: 'AUTORATE',
    TRANSPORT_MODE: 'TRANSPORT_MODE',
    MODE: 'MODE',
}

export const FILTER_TYPES_OPTIONS = [
    {value: FILTER_TYPES.ORIGIN, label: 'Origen'},
    {value: FILTER_TYPES.DESTINATION, label: 'Destino'},
    {value: FILTER_TYPES.INCOTERM, label: 'Incoterm'},
    {value: FILTER_TYPES.AUTORATE, label: 'Auto Rate'},
    {value: FILTER_TYPES.TRANSPORT_MODE, label: 'Transporte'},
    {value: FILTER_TYPES.MODE, label: 'Modo'},
];

export const DRAFT_TYPES = {
    NEW_QUOTATION: 'NEW_QUOTATION',
    NEW_EXPRESS: 'NEW_EXPRESS',
    NEW_PRICING: 'NEW_PRICING',
}

export const ROUTES = {
    HOME: {path: "/home", permission: "", label: "Home"},
    LOGIN: {path: "/login", permission: "", label: "Login"},
    DASHBOARD: {path: "/dashboard", permission: PERMISSION.CONSULTAR_STATS, label: "Dashboard"},
    MY_QUOTATIONS: {path: "/mis-cotizaciones", permission: PERMISSION.COMERCIAL, label: "Mis Cotizaciones"},
    MY_QUOTATIONS_HISTORY: {path: "/mis-cotizaciones/historial", permission: PERMISSION.COMERCIAL, label: "Mis Cotizaciones Historial"},
    FINALIZE_QUOTE: {path: "/finalizar", permission: PERMISSION.COMERCIAL},
    ASSIGNED: {path: "/asignadas", permission: PERMISSION.COMERCIAL, label: "Asignadas"},
    QUOTATIONS_MANAGER: {path: "/cotizaciones-gerente", permission: PERMISSION.GERENTE_COMERCIAL, label: "Cotizaciones"},
    PRICING_NEW_REQUESTS: {path: "/pricing/nuevas-solicitudes", permission: PERMISSION.PRICING, label: "Nuevas Solicitudes"},
    PRICING_IN_PROGRESS: {path: "/pricing/en-proceso", permission: PERMISSION.PRICING, label: "En Proceso"},
    PRICING_QUOTATIONS: {path: "/pricing/cotizaciones", permission: PERMISSION.PRICING, label: "Cotizaciones"},
    PRICING_QUOTE: {path: "/pricing/cotizador", permission: PERMISSION.PRICING, label: "Nueva Cotización"},
    ORGANIZATIONS: {path: "/organizaciones", permission: PERMISSION.CONSULTAR_ORGANIZACION, label: "Organizaciones"},
    PROVIDERS: {path: "/proveedores", permission: PERMISSION.PRICING, label: "Proveedores"},
    CHARGE_CODES: {path: "/charge-codes", permission: PERMISSION.ALTA_CHARGE_CODE, label: "Charge Codes"},
    USERS: {path: "/usuarios", permission: PERMISSION.MODIFICAR_USUARIO, label: "Usuarios"},
    EXPRESS_OPERATIONS: {path: "/operativa/express", permission: PERMISSION.CONSULTAR_SHIPMENT, label: "Express"},
    EXPRESS_OPERATIONS_IN_PROGRESS: {path: "/operativa/express/en-proceso", permission: PERMISSION.CONSULTAR_SHIPMENT, label: "En proceso"},
    EXPRESS_OPERATIONS_FINISHED: {path: "/operativa/express/finalizadas", permission: PERMISSION.CONSULTAR_SHIPMENT, label: "Finalizadas"},
    PRICING_RATES: {path: "/pricing/tarifas", permission: PERMISSION.PRICING, label: "Tarifas"},
    PRICING_QUOTATION_RULES: {path: "/pricing/reglas-cotizacion", permission: PERMISSION.PRICING_RULES, label: "Reglas de Cotización"},
    DRAFTS: {path: "/borradores", permission: PERMISSION.CONSULTAR_COTIZACION, label: "Borradores"},
    NOTES: {path: "/notas", permission: PERMISSION.PRICING, label: "Notas"},
    THIRD_PARTY: {path: "/third-party", permission: "", label: "Third Party"},
}

export const AUTO_INCREMENT_STEP = 1;

export const MAX_UPLOAD_SIZE = {label: '20MB', value: 20 * 1024 ** 2}

export const NO_RESULTS_FOUND_OPTION = {value: '', label: "No se encontraron resultados", disabled: true};
