import React, {useContext, useEffect, useMemo, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import providerController from "../../../../controller/providerController";
import Title from "../../../../components/base/Title/Title";
import THead from "../../../../components/base/Table/THead";
import TRow from "../../../../components/base/Table/TRow";
import THeader from "../../../../components/base/Table/THeader";
import TBody from "../../../../components/base/Table/TBody";
import Table from "../../../../components/base/Table/Table";
import TData from "../../../../components/base/Table/TData";
import Form from "react-bootstrap/Form";
import {notifications} from "@mantine/notifications";
import Checkbox from "../../../../components/base/Checkbox/Checkbox";
import Button from "../../../../components/base/Button/Button";
import {LoadingContext} from "../../../../context/LoadingContext";

function ContactTable ({provider, origin, transportMode, mode, onClose, addContacts, defaultValues}) {
    const {setLoading} = useContext(LoadingContext);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const allChecked = useMemo(() => contacts.every(c => selectedContacts.some(sc => c.id === sc.id)) && selectedContacts.length > 0,
        [selectedContacts, contacts]);

    async function getContacts() {
        setLoading(true);

        try {
            const contactFilter = {
                providerId: provider.id,

                transportMode: transportMode,
                mode: mode,

                originCode: origin,
            };

            const data = await providerController.getContacts(contactFilter);
            setContacts(data);
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al obtener contactos",
                color: "red",
            });
        }

        setLoading(false);
    }

    useEffect(() => {
        setSelectedContacts(defaultValues);
    }, []);

    useEffect(() => {
        if (provider) {
            getContacts();
        }
    }, [provider]);

    function handleRowClick(contact) {
        if (selectedContacts.some(sc => sc.id === contact.id)) {
            setSelectedContacts(selectedContacts.filter(sc => sc.id !== contact.id));
        } else {
            setSelectedContacts([...selectedContacts, contact]);
        }
    }

    function handleCheckAll() {
        setSelectedContacts(allChecked ? [] : contacts);
    }

    function handleAddContacts() {
        if (selectedContacts.length === 0) {
            notifications.show({
                title: "Error!",
                message: "Debe seleccionar al menos un contacto",
                color: "red",
            });
            return;
        }

        addContacts(provider?.id, selectedContacts);
    }

    return (
        <Modal show={true} onHide={onClose} backdrop="static" keyboard={false} size={"xl"} nested={0}>
            <Modal.Header closeButton>
                <Title order={2}>{provider?.name}</Title>
            </Modal.Header>
            <Modal.Body>
                <Table stickyHeader>
                    <THead>
                        <TRow>
                            <THeader>
                                <Checkbox checked={allChecked} onChange={handleCheckAll}/>
                            </THeader>
                            <THeader>Nombre</THeader>
                            <THeader>Mail</THeader>
                            <THeader>Primario</THeader>
                            <THeader>Comentarios</THeader>
                        </TRow>
                    </THead>
                    <TBody>
                        {contacts.map(contact =>
                            <TRow key={contact.id} onClick={() => handleRowClick(contact)}>
                                <TData style={{width: '5%'}}>
                                    <Checkbox
                                        checked={selectedContacts.some(selectedContact => selectedContact.id === contact.id)}
                                        readOnly
                                    />
                                </TData>
                                <TData style={{width: '15%'}}>{contact.name}</TData>
                                <TData style={{width: '25%'}}>{contact.mail}</TData>
                                <TData style={{width: '10%'}}>
                                    <Form.Check>
                                        <Form.Check.Input type="checkbox"
                                                          checked={parseInt(contact.primary) === 1}
                                                          readOnly
                                                          disabled
                                        />
                                    </Form.Check>
                                </TData>
                                <TData style={{width: '45%'}}>{contact.comments}</TData>
                            </TRow>
                        )}
                    </TBody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAddContacts}>Agregar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactTable;