import React, {forwardRef} from "react";
import {Button as MantineButton} from "@mantine/core";

import styles from "./Button.module.css";

/**
 * @description Wrapper custom component for Mantine Button
 * @param bgVariant - Background variant | default: primary | primary | secondary | tertiary | error | success
 * @param size - Size of the button | default: xs | xs | sm | md | lg | xl | xxl
 * @param sizeVariant - Size variant | default: false | false | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36
 */
const Button = forwardRef(({bgVariant = 'primary', sizeVariant = 0, size = 'xs', ...props}, ref) => {
    return (
        <MantineButton classNames={styles} ref={ref} bg-variant={bgVariant}
                       size-variant={sizeVariant !== 0 ? sizeVariant : null} size={size} {...props} />
    );
});

export default Button;
