import React, {useContext, useEffect, useMemo, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import providerController from "../../../../controller/providerController";
import {notifications} from "@mantine/notifications";

import Button from "../../../../components/base/Button/Button";
import Title from "../../../../components/base/Title/Title";
import {LoadingContext} from "../../../../context/LoadingContext";
import {useIsFirstRender} from "@mantine/hooks";
import Table from "../../../../components/base/Table/Table";
import THead from "../../../../components/base/Table/THead";
import TRow from "../../../../components/base/Table/TRow";
import THeader from "../../../../components/base/Table/THeader";
import TBody from "../../../../components/base/Table/TBody";
import TData from "../../../../components/base/Table/TData";
import Checkbox from "../../../../components/base/Checkbox/Checkbox";

function SimpleAddProvidersModal({handleClose, origin, destination, transportMode, mode, setQuotationProviders}) {
    const {setLoading} = useContext(LoadingContext);
    const isFirstRender = useIsFirstRender();
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [providers, setProviders] = useState([]);
    const allChecked = useMemo(() => providers.every(p => selectedProviders.some(sp => p.id === sp.id)) && selectedProviders.length > 0,
        [selectedProviders, providers]);

    useEffect(() => {
        if (isFirstRender) {
            getProviders();
        }
    }, []);

    async function getProviders() {
        setLoading(true);

        try {
            const providerFilter = {
                originCode: origin,
                destinationCode: destination,
                transportMode: transportMode,
                mode: mode,
            }

            await providerController.getProvidersForQuotation(providerFilter).then(data => setProviders(data));
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al obtener proveedores.",
                color: "red",
            });

        }
        setLoading(false);
    }

    async function handleAddProvidersClick() {
        if (selectedProviders.length === 0) {
            notifications.show({
                title: "Error!",
                message: "Debe seleccionar al menos un proveedor.",
                color: "red",
            });
            return;
        }

        setQuotationProviders(selectedProviders);

    }

    function handleRowClick(provider) {
        if (selectedProviders.some(sp => sp.id === provider.id)) {
            setSelectedProviders(selectedProviders.filter(sp => sp.id !== provider.id));
        } else {
            setSelectedProviders([...selectedProviders, provider]);
        }
    }

    function handleCheckAll() {
        setSelectedProviders(allChecked ? [] : providers);
    }

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} scrollable={true}>
            <Modal.Header closeButton>
                <Title>Agregar Proveedores</Title>
            </Modal.Header>
            <Modal.Body>
                <Table stickyHeader mt={8}>
                    <THead>
                        <TRow>
                            <THeader>
                                <Checkbox checked={allChecked} onChange={handleCheckAll}/>
                            </THeader>
                            <THeader>Código</THeader>
                            <THeader>Nombre</THeader>
                        </TRow>
                    </THead>
                    <TBody>
                        {providers.map(provider =>
                            <TRow onClick={() => handleRowClick(provider)} key={provider.code}>
                                <TData style={{width: '5%'}}>
                                    <Checkbox
                                        checked={selectedProviders.some(selectedProvider => selectedProvider.id === provider.id)}
                                        readOnly
                                    />
                                </TData>
                                <TData>{provider.code}</TData>
                                <TData>{provider.name}</TData>
                            </TRow>
                        )}
                    </TBody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAddProvidersClick}>
                    Agregar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SimpleAddProvidersModal;