import {Group} from "@mantine/core"
import Button from "../base/Button/Button";


const ConfirmModal = ({context, id, innerProps}) => (
    <Group className={"mt-2"} justify="center">
        <Button bgVariant={"secondary"}
                onClick={() => context.closeModal(id)}
        >
            Cancelar
        </Button>
        <Button
            onClick={() => {
                innerProps?.onConfirm()
                context.closeModal(id)
            }}
        >
            Confirmar
        </Button>
    </Group>
);

export default ConfirmModal;