import httpsModule from '../util/http';
import {BASE_URL} from '../util/constants';

const PROVIDER_ENDPOINT = `${BASE_URL}/api/provider`;

const providerController = {
    getProviders: async (filter) => {
        try {
            const response = await httpsModule.post(`${PROVIDER_ENDPOINT}`, filter);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getProvidersForQuotation: async (filter) => {
        try {
            const response = await httpsModule.post(`${PROVIDER_ENDPOINT}/quotation`, filter);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getProvider: async (providerId) => {
        try {
            const response = await httpsModule.get(`${PROVIDER_ENDPOINT}/${providerId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateProvider: async (provider) => {
        try {
            const response = await httpsModule.put(`${PROVIDER_ENDPOINT}`, provider);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    createProvider: async (provider) => {
        try {
            const response = await httpsModule.post(`${PROVIDER_ENDPOINT}/create`, provider);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getContacts: async (filter) => {
        try {
            const response = await httpsModule.post(`${PROVIDER_ENDPOINT}/contacts`, filter);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default providerController;