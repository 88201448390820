import {Flex, ScrollArea} from "@mantine/core";
import styles from "./Container.module.css";

export default function ContainerScrollableY({styleVariant = "styled", children, ...props}) {
    return (
        <Flex style-variant={styleVariant} {...props}>
            <ScrollArea w={"100%"} classNames={styles}>
                {children}
            </ScrollArea>
        </Flex>
    )
}