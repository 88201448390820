import {Title as MantineTitle} from "@mantine/core";

/**
 * @description Wrapper custom component for Mantine Title
 * @param cVariant - Color variant of the text | default: text | primary | secondary | tertiary | success | error | text | color-surface[0-4]
 * @param order - Order of the title | default: 2
 */
export default function Title({cVariant = "text", order = 2,children, ...props}) {
    return (
        <MantineTitle c-variant={cVariant} order={order} {...props} > {children} </MantineTitle>
    )
}