import {Menu as MantineMenu} from '@mantine/core';

function Menu({children, ...props}) {
    return (
        <MantineMenu trigger="hover" shadow="md" zIndex={99999999} {...props}>
            {children}
        </MantineMenu>
    )
}

export default Menu;