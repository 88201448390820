import {Modal} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import shipmentExpressController from "../../controller/shipmentExpressController";
import ClientPanel from "../../components/ClientPanel";
import CostSellExpress from "./components/CostSellExpress";
import {Menu, NumberInput} from "@mantine/core";
import DocumentModal from "./components/DocumentModal";
import {DateInput} from "@mantine/dates";
import {validateNumericInputWithComma, viewDocument} from "../../util/functions";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import Container from "../../components/base/Container/Container";
import Title from "../../components/base/Title/Title";
import TextInput from "../../components/base/TextInput/TextInput";
import Button from "../../components/base/Button/Button";
import {LoadingContext} from "../../context/LoadingContext";

function OperacionExpress({reference, handleClose}) {
    const {setLoading} = useContext(LoadingContext);
    const [firstLoad, setFirstLoad] = useState(true);
    const [shipmentData, setShipmentData] = useState({});
    const [requote, setRequote] = useState(true);
    const [showDocModal, setShowDocModal] = useState(false);

    useEffect(() => {
        setFirstLoad(true);

        async function getShipmentData() {
            setLoading(true);
            await shipmentExpressController.getShipment(reference)
                .then((data) => {
                    setShipmentData(data);

                    if (data.status === "COMPLETED") {
                        setRequote(false);
                    }
                })
                .catch(() => {
                    notifications.show({
                        title: "Error!",
                        message: "Error al obtener operación.",
                        color: "red",
                    });
                })
                .finally(() => {
                    setLoading(false);
                })
        }

        getShipmentData().then(() => {
            setFirstLoad(false);
        });
    }, [reference]);

    function updateShipmentData(values) {
        setShipmentData((prevState) => ({
            ...prevState,
            ...values,
        }));
    }

    function handleChange(e) {
        const {name, value} = e.target;
        setShipmentData(prevState => ({
            ...prevState,
            [name]: validateNumericInputWithComma(value),
        }));
    }

    function handleCostChange(e) {
        const {name, value} = e.target;
        setShipmentData(prevState => ({
            ...prevState,
            cost: {
                ...prevState.cost,
                [name]: value,
            }
        }));
    }

    function handleFreightChange(e) {
        const {value} = e.target;
        // search charge with concept FRT and update its amount
        const costCharges = shipmentData.cost.charges;
        costCharges.find(charge => charge.concept === "FRT").amount = value;

        setShipmentData(prevState => ({
            ...prevState,
            cost: {
                ...prevState.cost,
                charges: costCharges,
            }
        }));
    }

    function handleShipmentChange(e) {
        const {name, value} = e.target;
        setShipmentData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleShipmentChangeByName(name, value) {
        if (name === "arrivedPackages") {
            setShipmentData(prevState => ({
                ...prevState,
                [name]: value,
                sentPackages: value,
            }));
        } else {
            setShipmentData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    async function requoteShipment() {
        setLoading(true);

        const shipmentRequoteRequest = {
            reference: shipmentData.reference,
            origin: shipmentData.originCountry,
            destination: shipmentData.destinationCountry,

            isDoc: shipmentData.isDoc,

            totalValue: shipmentData.totalValue,
            totalValueCurrency: shipmentData.totalValueCurrency,

            insurance: shipmentData.insurance,

            sell: {...shipmentData.sell},
            cost: {...shipmentData.cost},
        }

        await shipmentExpressController.requoteShipment(shipmentRequoteRequest)
            .then((data) => {
                notifications.show({
                    title: "Operación re-cotizada!",
                    message: "Operación re-cotizada correctamente.",
                    color: "green",
                });

                setShipmentData(data);
            })
            .catch(() => {
                notifications.show({
                    title: "Error!",
                    message: "Error al re-cotizar operación.",
                    color: "red",
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    async function handleFinalizar() {
        setLoading(true);

        await shipmentExpressController.updateShipment(shipmentData)
        await shipmentExpressController.completeShipment(shipmentData.id)
            .then(() => {
                notifications.show({
                    title: "Operación finalizada!",
                    message: "Operación finalizada correctamente.",
                    color: "green",
                });
                setRequote(false);
            })
            .catch(() => {
                notifications.show({
                    title: "Error!",
                    message: "Error al finalizar la operación.",
                    color: "red",
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    async function handleSave() {
        setLoading(true);

        await shipmentExpressController.updateShipment(shipmentData)
            .then(() => {
                notifications.show({
                    title: "Operación guardada!",
                    message: "Operación guardada correctamente.",
                    color: "green",
                });
            })
            .catch(() => {
                notifications.show({
                    title: "Error!",
                    message: "Error al guardar operación.",
                    color: "red",
                });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    async function handleViewCartaFlete() {
        if (!shipmentData.cost?.invoicedChargeable) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar el Chargeable Facturado para poder emitir la Carta Flete.",
                color: "red",
            });
            return;
        } else if (!shipmentData.arrivedPackages) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar los bultos arribados para poder emitir la Carta Flete.",
                color: "red",
            });
            return;
        } else if (!shipmentData.sentPackages) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar los bultos del envío para poder emitir la Carta Flete.",
                color: "red",
            });
            return;
        } else if (!shipmentData.arrivalDate) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar la fecha de arribo para poder emitir la Carta Flete.",
                color: "red",
            });
            return;
        } else if (!shipmentData.waybill) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar la guía para poder emitir la Carta Flete.",
                color: "red",
            });
            return;
        }

        setLoading(true);
        try {
            await shipmentExpressController.updateShipment(shipmentData);
            await shipmentExpressController.getCartaFlete(shipmentData.reference)
                .then((data) => {
                    viewDocument({
                        nombre: data.name,
                        file: data.file,
                    });
                })
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al obtener la Carta Flete.",
                color: "red",
            });
        }
        setLoading(false);
    }

    return (
        <>
            {showDocModal && <DocumentModal onClose={() => setShowDocModal(false)} docs={shipmentData.documents}
                                            updateStateValues={updateShipmentData} editable={requote}/>}
            {!firstLoad &&
                <Modal show={true} onHide={handleClose} centered={true} dialogClassName="custom-modal"
                       backdrop="static">
                    <Modal.Header closeButton>
                        <Title>
                            {reference} - {shipmentData?.quotationData?.cargowise}
                            {shipmentData?.cost?.serviceType === "FEDEX_INTERNATIONAL_PRIORITY" && ' - IP'}
                            {shipmentData?.cost?.serviceType === "INTERNATIONAL_PRIORITY_FREIGHT" && ' - IPF'}
                        </Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*main section */}
                        <Container styleVariant={"unstyled"} direction={"row"} columnGap={8}>
                            {/* left section*/}
                            <Container styleVariant={"unstyled"} w={"70%"} direction={"column"} rowGap={8}>
                                {/* info section */}
                                <Container direction={"row"} wrap={"wrap"}>
                                    <div className='container-20'>
                                        <TextInput label={"Origen"} value={shipmentData.originCountryName} size={"xs"}
                                                   disabled/>
                                    </div>
                                    <div className='container-20'>
                                        <TextInput label={"Destino"} value={shipmentData.destinationCountryName}
                                                   size={"xs"}
                                                   disabled/>
                                    </div>
                                    <div className='container-12'>
                                        <TextInput label={`Valor (${shipmentData.totalValueCurrency})`}
                                                   name={"totalValue"}
                                                   value={shipmentData.totalValue} size={"xs"}
                                                   disabled={!requote}
                                                   onChange={handleChange}/>
                                    </div>
                                    <div className='container-12'>
                                        <TextInput label={"Peso total (KG)"} value={shipmentData.totalWeight}
                                                   size={"xs"}
                                                   disabled/>
                                    </div>
                                    <div className='container-12'>
                                        <TextInput label={"Volumen total (M3)"} value={shipmentData.totalVolume}
                                                   size={"xs"}
                                                   disabled/>
                                    </div>
                                    <div className='container-12'>
                                        <TextInput label={"Chargeable (KG)"} value={shipmentData.totalChargeableWeight}
                                                   size={"xs"} disabled/>
                                    </div>
                                    <div className='container-12'>
                                        <TextInput label={"Seguro"} value={shipmentData.insurance} size={"xs"}
                                                   disabled/>
                                    </div>
                                    <div className='container-20 mt-1'>
                                        <TextInput label={"Chargeable Facturado (KG)"}
                                                   value={shipmentData.cost?.invoicedChargeable}
                                                   name={"invoicedChargeable"} size={"xs"} disabled={!requote}
                                                   onChange={handleCostChange}/>
                                    </div>
                                    <div className='container-20 mt-1'>
                                        <TextInput label={"Costo Fuel (%)"} value={shipmentData.cost?.fsiPercentage}
                                                   name={"fsiPercentage"} size={"xs"} disabled={!requote}
                                                   onChange={handleCostChange}/>
                                    </div>
                                    <div className='container-20 mt-1'>
                                        <TextInput label={"Costo total de Flete Facturado"} size={"xs"}
                                                   disabled={!requote}
                                                   value={shipmentData.cost?.charges?.find(charge => charge.concept === "FRT")?.amount}
                                                   onChange={handleFreightChange}/>
                                    </div>
                                    <div className='container-20 mt-1'>
                                        {shipmentData?.sell?.sellPerKg ?
                                            <TextInput label={"Venta por KG"} value={shipmentData?.sell?.sellPerKg}
                                                       size={"xs"} disabled/>
                                            :
                                            <TextInput label={"Tarifario"} value={shipmentData?.sell?.tarifa}
                                                       size={"xs"}
                                                       disabled/>
                                        }
                                    </div>
                                    <div className='w-100 d-flex flex-wrap'>
                                        <div className='container-20 mt-1'>
                                            <NumberInput label={"Bultos Arribados"}
                                                         min={0}
                                                         value={Number(shipmentData.arrivedPackages)}
                                                         name={"arrivedPackages"}
                                                         size={"xs"}
                                                         disabled={!requote}
                                                         onChange={(value) => handleShipmentChangeByName('arrivedPackages', value)}
                                                         hideControls={true}
                                            />
                                        </div>
                                        <div className='container-20 mt-1'>
                                            <NumberInput label={"Bultos del Envío"}
                                                         min={0}
                                                         allowDecimal={false}
                                                         value={Number(shipmentData.sentPackages)}
                                                         size={"xs"}
                                                         disabled
                                                         onChange={(value) => handleShipmentChangeByName('sentPackages', value)}
                                                         hideControls={true}
                                            />
                                        </div>
                                        <div className='container-20 mt-1'>
                                            <DateInput label={"Fecha de Arribo"}
                                                       valueFormat="DD/MM/YYYY"
                                                       clearable={true}
                                                       value={shipmentData.arrivalDate}
                                                       name={"arrivalDate"} size={"xs"} disabled={!requote}
                                                       onChange={(value) => handleShipmentChangeByName('arrivalDate', value)}
                                                       popoverProps={{zIndex: 99999}}
                                            />
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex flex-wrap mb-2'>
                                        <div className='container-20 mt-1'>
                                            <TextInput label={"Shipment CW1"}
                                                       value={shipmentData.cargoWiseShipmentKey}
                                                       name={"cargoWiseShipmentKey"} size={"xs"} disabled={!requote}
                                                       onChange={handleShipmentChange}/>
                                        </div>
                                        <div className='container-20 mt-1'>
                                            <TextInput label={"Guia"}
                                                       value={shipmentData.waybill}
                                                       name={"waybill"} size={"xs"} disabled={!requote}
                                                       onChange={handleShipmentChange}/>
                                        </div>
                                        <div className='container-20 mt-1'>
                                            <TextInput label={"Factura Fedex"} value={shipmentData.costInvoice}
                                                       name={"costInvoice"} size={"xs"} disabled={!requote}
                                                       onChange={handleShipmentChange}/>
                                        </div>
                                        <div className='container-20 mt-1 d-flex align-items-end'>
                                            <Button onClick={() => setShowDocModal(!showDocModal)}>
                                                Documentos
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                                <CostSellExpress shipment={shipmentData} updateShipmentData={updateShipmentData}
                                                 editable={requote}/>
                            </Container>
                            {/* right section */}
                            <Container styleVariant={"unstyled"} w={"30%"} direction={"column"} rowGap={8}>
                                <ClientPanel preClient={shipmentData?.client} inCW={true}/>
                            </Container>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {requote && (
                            <div className={"w-100 d-flex justify-content-between"}>
                                <Menu trigger="hover" shadow="md" zIndex={99999999}>
                                    <Menu.Target>
                                        <Button bgVariant={"secondary"}>Acciones</Button>
                                    </Menu.Target>

                                    <Menu.Dropdown>
                                        <Menu.Label>Acciones</Menu.Label>
                                        <Menu.Item onClick={handleViewCartaFlete}>
                                            Emitir Carta Flete
                                        </Menu.Item>
                                        <Menu.Item onClick={() => {
                                            modals.openContextModal({
                                                modal: "confirm",
                                                title: "¿Está seguro que desea finalizar la operación?",
                                                innerProps: {
                                                    onConfirm: handleFinalizar,
                                                }
                                            });
                                        }}>
                                            Finalizar Operación
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                                <div className={"d-flex gap-3"}>
                                    <Button bgVariant={"secondary"} onClick={() => {
                                        modals.openContextModal({
                                            modal: "confirm",
                                            title: "¿Está seguro que desea recotizar la operación?",
                                            innerProps: {
                                                onConfirm: requoteShipment,
                                            }
                                        })
                                    }}>
                                        Re-cotizar
                                    </Button>
                                    <Button onClick={handleSave}>
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

export default OperacionExpress;