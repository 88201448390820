import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {ActionIcon} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTrash} from "@fortawesome/free-solid-svg-icons";
import {notifications} from "@mantine/notifications";
import Title from "./base/Title/Title";
import TextInput from "./base/TextInput/TextInput";
import Button from "./base/Button/Button";
import {PICKUP_INITIAL_STATE} from "../models/models";

function PickupTable({data, setData, editable = true}) {
    const [show, setShow] = useState(false);
    const [pickups, setPickups] = useState([]);
    const [showPickupDimensions, setShowPickupDimensions] = useState(false);

    useEffect(() => {
        if (data?.length > 0) {
            setPickups(data);
        } else {
            setPickups([{...PICKUP_INITIAL_STATE}]);
        }
    }, []);

    useEffect(() => {
        setShowPickupDimensions(pickups?.length > 1);
    }, [pickups]);

    function handleChange(e, index) {
        const {name, value} = e.target;

        setPickups(prevRows => {
            const newRows = [...prevRows];
            newRows[index][name] = value;
            return newRows;
        });
    }

    function addRow() {
        setPickups([...pickups, {...PICKUP_INITIAL_STATE}]);
    }

    function deleteRow(index) {
        setPickups(prevRows => {
            const newRows = [...prevRows];
            newRows.splice(index, 1);
            return newRows;
        });
    }

    function saveAndClose() {
        if (pickups.length < 1) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar al menos un pickup.",
                color: "red",
            });
            return;
        }

        for (let pickup of pickups) {
            if (!pickup.direccion ||
                !pickup.codPostal ||
                !pickup.ciudad ||
                !pickup.estado ||
                !pickup.proveedor ||
                !pickup.nroOrden ||
                (!pickup.volumen && pickups.length > 1) ||
                (!pickup.peso && pickups.length > 1)) {

                notifications.show({
                    title: "Error!",
                    message: "Debe llenar todos los campos antes de guardar.",
                    color: "red",
                });
                return;
            }
        }

        setData(pickups);
        setShow(false);
    }

    return (
        <>
            <ActionIcon className={"mb-1"} size={"sm"} variant="filled" onClick={() => setShow(true)}>
                <FontAwesomeIcon style={{width: '70%', height: '70%'}} icon={faBars}/>
            </ActionIcon>

            {show &&
                <Modal show={true} onHide={() => setShow(false)} dialogClassName="custom-modal" nested={0}>
                    <Modal.Header closeButton>
                        <Title>Pickups</Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"customTableContainerExtraPadding noBorder"}>
                            <table id="table-pickup">
                                <thead>
                                <tr>
                                    <th style={{paddingRight: "5px"}}>Dirección</th>
                                    <th>Cod. Postal</th>
                                    <th>Ciudad</th>
                                    <th>Estado</th>
                                    <th>Proveedor</th>
                                    <th>Nro. Orden</th>
                                    {showPickupDimensions &&
                                        <>
                                            <th>CBM</th>
                                            <th>Peso</th>
                                        </>
                                    }
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {pickups.map((pickup, index) => (
                                    <tr key={index}>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       value={pickup.direccion}
                                                       name={"direccion"}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       name={"codPostal"}
                                                       value={pickup.codPostal}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       name={"ciudad"}
                                                       value={pickup.ciudad}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       name={"estado"}
                                                       value={pickup.estado}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       name={"proveedor"}
                                                       value={pickup.proveedor}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInput disabled={!editable}
                                                       name={"nroOrden"}
                                                       value={pickup.nroOrden}
                                                       onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>

                                        {showPickupDimensions &&
                                            <>
                                                <td>
                                                    <TextInput disabled={!editable}
                                                               name={"volumen"}
                                                               value={pickup.volumen}
                                                               onChange={(e) => handleChange(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput disabled={!editable}
                                                               name={"peso"}
                                                               value={pickup.peso}
                                                               onChange={(e) => handleChange(e, index)}
                                                    />
                                                </td>
                                            </>
                                        }
                                        <td>
                                            {editable &&
                                                <ActionIcon color={"red"}
                                                            onClick={() => deleteRow(index)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </ActionIcon>
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {editable &&
                            <>
                                <Button id="add-row-button" onClick={addRow}>
                                    Agregar pickup
                                </Button>
                                <Button bgVariant={"secondary"} id="save-close-button"
                                        onClick={() => saveAndClose()}>
                                    Guardar y Cerrar
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default PickupTable;