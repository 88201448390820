import React, {useContext, useEffect, useState} from 'react';
import {TRANSLATION_KEYS} from '../util/constants';
import {ActionIcon} from "@mantine/core";
import {validateNumericInputWithComma} from "../util/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {LanguageContext} from "../context/LanguageContext";
import Button from "./base/Button/Button";
import TextInput from "./base/TextInput/TextInput";
import Select from "./base/Select/Select";
import Container from "./base/Container/Container";
import {PACKING_GENERAL_INITIAL_STATE} from "../models/models";

const PackingTable = ({updateStateValues, quotation, height, inCW}) => {
    const {t} = useContext(LanguageContext);
    const [rows, setRows] = useState(quotation ?
        rowsToString(quotation.packingData)
        :
        [{...PACKING_GENERAL_INITIAL_STATE}]);
    const [measureSystem, setMeasureSystem] = useState(quotation ? quotation.measureSystem : 'metric');

    function handleAddRow() {
        let rowType = 'PLT';
        if (rows.length > 0) {
            rowType = rows[rows.length - 1].ctnPlt;
        }
        setRows([...rows, {...PACKING_GENERAL_INITIAL_STATE, ctnPlt: rowType}]);
    }

    function handleRemoveRow(index) {
        const newRow = [...rows];
        newRow.splice(index, 1);
        setRows(newRow);

        const {totalCantidadPLT, totalCantidadCTN, totalKg, totalVolume, data} = calculateTotals(newRow);
        if (!isNaN(totalCantidadPLT) && !isNaN(totalCantidadCTN) && !isNaN(totalKg) && !isNaN(totalVolume)) {
            updateStateValues({
                pltTotal: totalCantidadPLT,
                ctnTotal: totalCantidadCTN,
                cbmTotal: totalVolume,
                pesoTotal: totalKg,
                packingData: data
            });
        }

        if (newRow.length === 0) {
            setRows([{ctnPlt: '', cantidad: '', kg: '', largo: '', ancho: '', alto: ''}]);
            updateStateValues({
                pltTotal: '',
                ctnTotal: '',
                cbmTotal: '',
                pesoTotal: '',
                packingData: []
            });
        }
    }

    function handleInputChange(value, index, field) {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);

        if (isRowComplete(updatedRows[index])) {
            const {totalCantidadPLT, totalCantidadCTN, totalKg, totalVolume, data} = calculateTotals(updatedRows);
            if (!isNaN(totalCantidadPLT) && !isNaN(totalCantidadCTN) && !isNaN(totalKg) && !isNaN(totalVolume)) {
                updateStateValues({
                    pltTotal: totalCantidadPLT,
                    ctnTotal: totalCantidadCTN,
                    cbmTotal: totalVolume,
                    pesoTotal: totalKg,
                    packingData: data
                });
            }
        }
    }

    function handleNumericInputChange(value, index, field) {
        handleInputChange(validateNumericInputWithComma(value), index, field);
    }

    function calculateTotals(updatedRows) {
        let totalCantidadPLT = 0;
        let totalCantidadCTN = 0;
        let totalKg = 0;
        let totalVolume = 0;

        let data = [];
        updatedRows.forEach((row) => {
            const cantidad = parseFloat(row.cantidad);
            const kg = measureSystem === "metric" ? parseFloat(row.kg) : parseFloat(row.kg) * 0.453592;
            const ancho = measureSystem === "metric" ? parseFloat(row.ancho) : parseFloat(row.ancho) * 0.0254;
            const alto = measureSystem === "metric" ? parseFloat(row.alto) : parseFloat(row.alto) * 0.0254;
            const largo = measureSystem === "metric" ? parseFloat(row.largo) : parseFloat(row.largo) * 0.0254;

            if (row.ctnPlt === 'PLT') {
                totalCantidadPLT += cantidad;
            } else if (row.ctnPlt === 'CTN') {
                totalCantidadCTN += cantidad;
            }

            data = data.concat(row)

            totalKg += kg * cantidad;
            totalVolume += (ancho * alto * largo) * cantidad;
        });

        totalKg = Number(totalKg.toFixed(3));
        totalVolume = Number(totalVolume.toFixed(3));

        return {
            totalCantidadPLT,
            totalCantidadCTN,
            totalKg,
            totalVolume,
            data
        };
    }

    function isRowComplete(row) {
        return (row.ctnPlt && row.cantidad && row.kg && row.largo && row.ancho && row.alto);
    }


    useEffect(() => {
        if (quotation?.packingData?.length > 0) {
            const {totalCantidadPLT, totalCantidadCTN, totalKg, totalVolume} = calculateTotals(rows);
            if (!isNaN(totalCantidadPLT) && !isNaN(totalCantidadCTN) && !isNaN(totalKg) && !isNaN(totalVolume)) {
                updateStateValues({
                    pltTotal: totalCantidadPLT,
                    ctnTotal: totalCantidadCTN,
                    cbmTotal: totalVolume,
                    pesoTotal: totalKg,
                    packingData: rows,
                    measureSystem: measureSystem
                });
            }
        }
    }, [measureSystem])

    useEffect(() => {
        if (quotation) {
            if (quotation.packingData.length > 0) {
                setRows(rowsToString(quotation.packingData));
                setMeasureSystem(quotation.measureSystem);
                const {totalCantidadPLT, totalCantidadCTN, totalKg, totalVolume, data} = calculateTotals(rows);
                if (!isNaN(totalCantidadPLT) && !isNaN(totalCantidadCTN) && !isNaN(totalKg) && !isNaN(totalVolume)) {
                    if (quotation.pltTotal === totalCantidadPLT && quotation.ctnTotal === totalCantidadCTN
                        && quotation.cbmTotal === totalVolume && quotation.pesoTotal === totalKg) {
                        return;
                    }
                    updateStateValues({
                        pltTotal: totalCantidadPLT,
                        ctnTotal: totalCantidadCTN,
                        cbmTotal: totalVolume,
                        pesoTotal: totalKg,
                        packingData: data
                    });
                }
            } else {
                setRows([{ctnPlt: 'PLT', cantidad: 0, kg: 0, largo: 0, ancho: 0, alto: 0}]);
            }
        }
    }, [quotation])

    return (
        <Container direction={"column"} rowGap={8} styleVariant={"unstyled"}>
            {!inCW &&
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
                    <div style={{width: '10%'}}>
                        <Select value={measureSystem} disabled={inCW}
                                onChange={(value) => setMeasureSystem(value)}
                                data={[
                                    {value: "metric", label: "kg/m"},
                                    {value: "imperial", label: "lb/in"}
                                ]}
                        />
                    </div>
                </div>
            }
            <div className="customTableContainerExtraPadding noBorder custom-scroll overflow-auto"
                 style={{maxHeight: `${height}px`}}>
                <table>
                    <thead>
                    <tr>
                        <th>CTN/PLT</th>
                        <th>{t(TRANSLATION_KEYS.QUANTITY)}</th>
                        <th>{t(TRANSLATION_KEYS.WEIGHT)} ({measureSystem === "metric" ? "kg" : "lb"})</th>
                        <th>{t(TRANSLATION_KEYS.LENGTH)} ({measureSystem === "metric" ? "m" : "in"})</th>
                        <th>{t(TRANSLATION_KEYS.WIDTH)} ({measureSystem === "metric" ? "m" : "in"})</th>
                        <th>{t(TRANSLATION_KEYS.HEIGHT)} ({measureSystem === "metric" ? "m" : "in"})</th>
                        {!inCW &&
                            <th></th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td>
                                <Select
                                    onChange={(value) => handleInputChange(value, index, 'ctnPlt')}
                                    data={[
                                        {value: "PLT", label: "PLT"},
                                        {value: "CTN", label: "CTN"}
                                    ]}
                                    value={row.ctnPlt}
                                    disabled={inCW}
                                />
                            </td>
                            <td>
                                <TextInput name={"cantidad"}
                                           disabled={inCW} value={row.cantidad || ''}
                                           onChange={(e) => handleNumericInputChange(e.target.value, index, 'cantidad')}
                                           autoFocus={rows.length > 1}
                                />
                            </td>
                            <td>
                                <TextInput name={"kg"}
                                           disabled={inCW} value={row.kg || ''}
                                           onChange={(e) => handleNumericInputChange(e.target.value, index, 'kg')}
                                />
                            </td>
                            <td>
                                <TextInput name={"largo"}
                                           disabled={inCW} value={row.largo || ''}
                                           onChange={(e) => handleNumericInputChange(e.target.value, index, 'largo')}
                                />
                            </td>
                            <td>
                                <TextInput name={"ancho"}
                                           disabled={inCW} value={row.ancho || ''}
                                           onChange={(e) => handleNumericInputChange(e.target.value, index, 'ancho')}
                                />
                            </td>
                            <td>
                                <TextInput name={"alto"}
                                           disabled={inCW} value={row.alto || ''}
                                           onChange={(e) => handleNumericInputChange(e.target.value, index, 'alto')}
                                />
                            </td>
                            {!inCW &&
                                <td>
                                    <ActionIcon color={"red"} onClick={() => handleRemoveRow(index)} disabled={inCW}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </ActionIcon>
                                </td>
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                {!inCW &&
                    <Button onClick={handleAddRow}>
                        Agregar Pack
                    </Button>
                }
            </div>
        </Container>
    );
};

export default PackingTable;


function rowsToString(rows) {
    return rows?.map(row => {
        const transformedRows = {...row};
        Object.keys(transformedRows).forEach(key => {
            if (typeof transformedRows[key] === "number") {
                transformedRows[key] = transformedRows[key].toString();
            }
        });
        return transformedRows;
    });
}