import React, {useEffect, useState} from 'react';
import ContactTable from './ContactTable';
import {Pill} from '@mantine/core';
import {notifications} from "@mantine/notifications";
import Container from "../../../../components/base/Container/Container";
import Table from "../../../../components/base/Table/Table";
import TRow from "../../../../components/base/Table/TRow";
import TBody from "../../../../components/base/Table/TBody";
import TData from "../../../../components/base/Table/TData";
import Text from "../../../../components/base/Text/Text";
import ContainerScrollableY from "../../../../components/base/Container/ContainerScrollableY";

const ProvidersTable = ({transportMode, mode, providers, origin, setQuotationContacts}) => {
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [contacts, setContacts] = useState([]);

    const handleCloseModal = () => {
        setSelectedProvider(null);
    };

    function handleClick(agent) {
        if (!mode) {
            notifications.show({
                title: "Error!",
                message: "Debe ingresar un modo de transporte.",
                color: "red",
            });
        } else {
            setSelectedProvider(agent);
        }
    }

    function addContacts(providerId, selectedContacts) {
        // append selectedContacts
        const newContacts = [...contacts, ...selectedContacts]

        // remove duplicates
        const uniqueContacts = newContacts.filter((contact, index, self) =>
            index === self.findIndex((t) => (
                t.providerId === contact.providerId && t.id === contact.id
            ))
        );

        // remove previous contacts from the same provider that are not in selectedContacts
        const updatedContacts = uniqueContacts.filter((contact) => contact.providerId !== providerId || selectedContacts.some(sc => sc.id === contact.id));

        setContacts(updatedContacts);
        handleCloseModal();
    }

    function handleDeleteButton(contactId) {
        const updatedContacts = contacts.filter((contact) => contact.id !== contactId);
        setContacts(updatedContacts);
        setQuotationContacts(updatedContacts);
    }

    useEffect(() => {
        setQuotationContacts(contacts);
    }, [contacts]);

    useEffect(() => {
        setContacts([]);
    }, [transportMode, mode]);

    return (
        <>
            {selectedProvider && (
                <ContactTable provider={selectedProvider} origin={origin} transportMode={transportMode}
                              mode={mode} onClose={handleCloseModal} addContacts={addContacts}
                              defaultValues={contacts.filter(c => c.providerId === selectedProvider.id)}
                />
            )}
            <Container styleVariant={"unstyled"} pt={4} gap={16}>
                <Container styleVariant={"unstyled"} direction={"column"} style={{width: '30%'}}>
                    <div className={"mb-1 border-bottom-label"}>
                        <Text fw={750} cVariant={"primary"} style={{textAlign: "center"}}>Proveedores</Text>
                    </div>
                    <Table style={{maxHeight: 200}} fsVariant={"xs"}>
                        <TBody>
                            {providers.map((provider) => (
                                <TRow key={provider.code} onClick={() => handleClick(provider)}>
                                    <TData>{provider.name}</TData>
                                </TRow>
                            ))}
                        </TBody>
                    </Table>
                </Container>
                <Container styleVariant={"unstyled"} direction={"column"} style={{width: '70%'}}>
                    <div className={"mb-1 border-bottom-label"}>
                        <Text fw={750} cVariant={"primary"} style={{textAlign: "center"}}>
                            Contactos Seleccionados
                        </Text>
                    </div>
                    <ContainerScrollableY styleVariant={"unstyled"} justify={"center"} style={{height: 200}}>
                        <div className={"d-flex justify-content-center m-2"}>
                            <Pill.Group>
                                {contacts.map((contact) => (
                                    <Pill key={contact.id} withRemoveButton
                                          onRemove={() => handleDeleteButton(contact.id)}>
                                        {contact.providerCode} - {contact.name}
                                    </Pill>
                                ))}
                            </Pill.Group>
                        </div>
                    </ContainerScrollableY>
                </Container>
            </Container>
        </>
    );
};

export default ProvidersTable;
