import {
    Dropzone as MantineDropzone,
    IMAGE_MIME_TYPE,
    MS_EXCEL_MIME_TYPE,
    MS_POWERPOINT_MIME_TYPE,
    MS_WORD_MIME_TYPE,
    PDF_MIME_TYPE
} from "@mantine/dropzone";
import {Group, rem} from "@mantine/core";
import {IconFileCheck, IconFiles, IconFileX} from "@tabler/icons-react";
import Text from "../Text/Text";
import React from "react";
import Container from "../Container/Container";
import {notifications} from "@mantine/notifications";
import {MAX_UPLOAD_SIZE} from "../../../util/constants";
import {isFileSizeValid} from "../../../util/functions";

export default function Dropzone({
                                     onlySelectOne = false,
                                     onlySpreadsheets = false,
                                     onDrop,
                                     onRejectCustomActions = null
                                 }) {
    return (
        <MantineDropzone.FullScreen
            onDrop={(files) => onDrop(onlySelectOne ? files[0] : files)}
            maxSize={MAX_UPLOAD_SIZE.value}
            onReject={(files) => {
                let errorMessage = "Error al cargar los archivos."

                if (onlySelectOne) {
                    if (!isFileSizeValid(files[0]?.file?.size)) {
                        errorMessage += ` Tamaño maximo: ${MAX_UPLOAD_SIZE.label}`
                    }
                } else {
                    let invalidFiles = 0
                    files.map((file) => (
                        isFileSizeValid(file?.file?.size) ? null : invalidFiles += 1
                    ))
                    if (invalidFiles > 0) {
                        errorMessage += ` Se encontraron archivos que superan el tamaño maximo: ${MAX_UPLOAD_SIZE.label}`
                    }
                }

                notifications.show({
                    title: "Error!",
                    message: errorMessage,
                    color: "red",
                })
                if (onRejectCustomActions === null) {
                    return
                }
                onRejectCustomActions()
            }}
            accept={
                onlySpreadsheets ?
                    [MS_EXCEL_MIME_TYPE]
                    :
                    [IMAGE_MIME_TYPE, PDF_MIME_TYPE, MS_WORD_MIME_TYPE, MS_EXCEL_MIME_TYPE, MS_POWERPOINT_MIME_TYPE]
            }
            style={{width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0, zIndex: 99999}}
        >
            <Group bg-variant={"surface-070"} justify="center" gap="xl" mih={120} h={"100vh"}>
                <MantineDropzone.Accept>
                    <Container styleVariant={"unstyled"} direction={"row"} justify={"center"} align={"center"}>
                        <IconFileCheck
                            c-variant={"success"}
                            style={{width: rem(52), height: rem(52)}}
                            stroke={1.5}
                        />
                        <Text size={"md"} inline>
                            Suelte aquí los archivo a subir.
                        </Text>
                    </Container>
                </MantineDropzone.Accept>
                <MantineDropzone.Reject>
                    <Container styleVariant={"unstyled"} direction={"row"} justify={"center"} align={"center"}>
                        <IconFileX
                            c-variant={"error"}
                            style={{width: rem(52), height: rem(52)}}
                            stroke={1.5}
                        />
                        <Text size={"md"} inline>
                            Tipo de archivo no soportado.
                        </Text>
                    </Container>
                </MantineDropzone.Reject>
                <MantineDropzone.Idle>
                    <Container styleVariant={"unstyled"} direction={"row"}>
                        <IconFiles
                            c-variant={"text"}
                            style={{width: rem(52), height: rem(52)}}
                            stroke={1.5}
                        />
                        <Text size={"md"} inline>
                            Cargando...
                        </Text>
                    </Container>
                </MantineDropzone.Idle>
            </Group>
        </MantineDropzone.FullScreen>
    )
}