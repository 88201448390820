import React, {useContext, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import controller from "../controller/controller.js";
import {ActionIcon} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {notifications} from "@mantine/notifications";

import Button from "./base/Button/Button";
import TextInput from "./base/TextInput/TextInput";
import Title from "./base/Title/Title";
import Table from "./base/Table/Table";
import THead from "./base/Table/THead";
import TRow from "./base/Table/TRow";
import THeader from "./base/Table/THeader";
import TBody from "./base/Table/TBody";
import TData from "./base/Table/TData";
import Container from "./base/Container/Container";
import {SimpleGrid} from "./base/SimpleGrid/SimpleGrid";
import {LoadingContext} from "../context/LoadingContext";

function UnlocoTable({label, setUnloco}) {
    const {setLoading} = useContext(LoadingContext);
    const [filter, setFilter] = useState({
        name: '',
        iata: '',
        country: '',
    });
    const [unlocos, setUnlocos] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFilter({
            name: '',
            iata: '',
            country: '',
        });
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleChangeByName = (name, value) => {
        setFilter(prevState => ({...prevState, [name]: value}));
    };

    async function handleSearchClick() {
        setLoading(true);

        if (filter.name.trim() === "" && filter.iata.trim() === "" && filter.country.trim() === "") {
            notifications.show({
                title: "Error!",
                message: "Ingrese al menos un filtro de búsqueda.",
                color: "red",
            });
            setLoading(false);
            return;
        }

        let alertMessage;
        try {
            const data = await controller.getUnlocos(filter);
            if (data.length > 10000) {
                alertMessage = 'Cantidad de resultados excede el límite de 10.000';
            } else if (data.length === 0) {
                alertMessage = 'No se encontraron resultados';
                setUnlocos([]);
            } else {
                setUnlocos(data);
            }
        } catch (error) {
            alertMessage = "Error al buscar UNLOCOS.";
        }

        if (alertMessage !== undefined) {
            notifications.show({
                title: "Error!",
                message: alertMessage,
                color: "red",
            });
        }

        setLoading(false);
    }

    const handleRowClick = (data) => {
        setFilter({name: '', iata: '', country: ''})

        setUnloco({name: data.nombre, code: data.codigo});

        setShow(false);
    };

    return (
        <>
            <ActionIcon size={"sm"} variant="filled" onClick={handleShow}>
                <FontAwesomeIcon style={{width: '70%', height: '70%'}} icon={faBars}/>
            </ActionIcon>

            <Modal show={show}
                   onHide={handleClose}
                   backdrop="static"
                   keyboard={false}
                   nested={0}
                   size={"lg"}
            >
                <Modal.Header closeButton>
                    <Title>Buscar {label}</Title>
                </Modal.Header>
                <Modal.Body>
                    <Container styleVariant={"unstyled"} direction={"column"} p={8}>
                        <TextInput label={"Nombre"} className={"mb-2"} placeholder={"Buscar..."}
                                   value={filter.name} onChange={(e) => handleChangeByName("name", e.target.value)}
                                   onKeyPress={(e) => e.key === "Enter" && handleSearchClick()}
                        />
                        <SimpleGrid cols={2} w={"100%"}>
                            <TextInput label={"Iata"} placeholder="Buscar..."
                                       value={filter.iata}
                                       onChange={(e) => handleChangeByName("iata", e.target.value)}
                                       onKeyPress={(e) => e.key === "Enter" && handleSearchClick()}
                            />
                            <TextInput label={"País"} type="text" id="descripcion"
                                       placeholder="Buscar..."
                                       value={filter.country}
                                       onChange={(e) => handleChangeByName("country", e.target.value)}
                                       onKeyPress={(e) => e.key === "Enter" && handleSearchClick()}
                            />
                        </SimpleGrid>
                    </Container>

                    <Container styleVariant={"unstyled"} justify={"flex-end"} p={8}>
                        <Button onClick={handleSearchClick}>
                            Buscar
                        </Button>
                    </Container>
                    {unlocos.length > 0 && (
                        <Table stickyHeader>
                            <THead>
                                <TRow>
                                    <THeader>Código</THeader>
                                    <THeader>Nombre</THeader>
                                    <THeader>IATA</THeader>
                                    <THeader>País</THeader>
                                </TRow>
                            </THead>
                            <TBody>
                                {unlocos.map(unloco =>
                                    <TRow onClick={() => handleRowClick(unloco)} key={unloco.code}>
                                        <TData>{unloco.codigo}</TData>
                                        <TData>{unloco.nombre}</TData>
                                        <TData>{unloco.iata}</TData>
                                        <TData>{unloco.country}</TData>
                                    </TRow>
                                )}
                            </TBody>
                        </Table>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UnlocoTable;