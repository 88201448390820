import React, {useContext, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import controller from "../controller/controller.js";
import {ActionIcon} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {notifications} from "@mantine/notifications";

import Title from "./base/Title/Title";
import Button from "./base/Button/Button";
import TextInput from "./base/TextInput/TextInput";
import Table from "./base/Table/Table";
import THead from "./base/Table/THead";
import TBody from "./base/Table/TBody";
import TRow from "./base/Table/TRow";
import THeader from "./base/Table/THeader";
import TData from "./base/Table/TData";
import Container from "./base/Container/Container";
import {SimpleGrid} from "./base/SimpleGrid/SimpleGrid";
import {LoadingContext} from "../context/LoadingContext";

const CountryTable = ({setCountry}) => {
    const {setLoading} = useContext(LoadingContext);
    const [filter, setFilter] = useState({code: '', name: ''});
    const [countries, setCountries] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setFilter({code: '', name: ''});
        setShow(false);
    };

    const handleShow = () => setShow(true);

    function handleChangeByName(name, value) {
        setFilter(prevState => ({...prevState, [name]: value}));
    }

    async function handleSearchClick() {
        setLoading(true);

        try {
            const data = await controller.getCountries(filter);
            if (data.length === 0) {
                notifications.show({
                    title: "Error!",
                    message: "No se encontraron resultados.",
                    color: "red",
                });
                return;
            }
            setCountries(data);
        } catch (error) {
            notifications.show({
                title: "Error!",
                message: "Error al buscar Países.",
                color: "red",
            });
        }

        setLoading(false);
    }

    const handleRowClick = (data) => {
        setFilter({code: '', name: ''});
        setCountry({code: data.code, name: data.name});

        setShow(false);
    };

    return (
        <>
            <ActionIcon size={"sm"} variant="filled" onClick={handleShow}>
                <FontAwesomeIcon style={{width: '70%', height: '70%'}} icon={faBars}/>
            </ActionIcon>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                nested={0}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Title>Buscar País</Title>
                </Modal.Header>
                <Modal.Body>
                    <Container styleVariant={"unstyled"} p={8}>
                        <SimpleGrid cols={2} w={"100%"}>
                            <TextInput label={"Código"}
                                       placeholder={"Buscar..."}
                                       value={filter.code}
                                       onChange={(e) => handleChangeByName("code", e.target.value)}
                                       onKeyPress={(e) => e.key === "Enter" && handleSearchClick()}
                            />
                            <TextInput label={"Nombre"}
                                       placeholder={"Buscar..."}
                                       value={filter.name}
                                       onChange={(e) => handleChangeByName("name", e.target.value)}
                                       onKeyPress={(e) => e.key === "Enter" && handleSearchClick()}
                            />
                        </SimpleGrid>
                    </Container>
                    <Container styleVariant={"unstyled"} justify={"flex-end"} p={8}>
                        <Button onClick={handleSearchClick}>
                            Buscar
                        </Button>
                    </Container>
                    {countries?.length > 0 && (
                        <Table stickyHeader>
                            <THead>
                                <TRow>
                                    <THeader>Código</THeader>
                                    <THeader>Nombre</THeader>
                                    <THeader>Continente</THeader>
                                    <THeader>Región</THeader>
                                </TRow>
                            </THead>
                            <TBody>
                                {countries.map(pais =>
                                    <TRow onClick={() => handleRowClick(pais)} key={pais.code}>
                                        <TData>{pais.code}</TData>
                                        <TData>{pais.name}</TData>
                                        <TData>{pais.continent}</TData>
                                        <TData>{pais.region}</TData>
                                    </TRow>
                                )}
                            </TBody>
                        </Table>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CountryTable;