export const STORAGE = {
    TOKEN: "access_token",
    LANGUAGE: "language",
    DRAFTS: "drafts",
    DRAFTS_AUTOINCREMENT: "drafts_autoincrement",
    get: (key) => {
        const value = localStorage.getItem(key);
        if (!value) return "";
        return JSON.parse(value);
    },
    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    },
    remainingSpace: () => {
        const usage = JSON.stringify(localStorage).length;
        const total = 5 * 1024 * 1024;
        const free = total - usage;
        return free / total * 100;
    },
    getAutoIncrement: (key) => {
        const value = localStorage.getItem(key);

        if (!value) {
            localStorage.setItem(key, 0);
            return 0;
        } else {
            let currentValue = parseInt(value);
            const incrementedValue = currentValue + 1;

            localStorage.setItem(key, incrementedValue);

            return incrementedValue;
        }
    },
    deleteDraftById: (id) => {
        const drafts = STORAGE.get(STORAGE.DRAFTS);
        const newDrafts = drafts.filter(d => d.id !== id);
        STORAGE.set(STORAGE.DRAFTS, newDrafts);
    }
};
