import TextInput from "../base/TextInput/TextInput";
import controller from "../../controller/controller";
import CommodityTable from "../CommodityTable";
import {notifications} from "@mantine/notifications";
import React from "react";
import Text from "../base/Text/Text";
import Container from "../base/Container/Container";

export default function CommodityAsyncInput({commodity, setCommodity, error, errorMsg, ...props}) {
    function handleChange(e) {
        const {name, value} = e.target;
        setCommodity((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    async function handleEventChange(event) {
        /* allow the user to clear the fields if input was empty and an event is triggered */
        if (!commodity?.code) {
            setCommodity({code: "", description: ""});
            return;
        }

        if ((event.key === "Tab" || event.key === "Enter" || event.type === "blur") && commodity?.code !== "") {
            try {
                const data = await controller.getCommodities({code: commodity?.code});
                if (data.length === 0) {
                    setCommodity({code: "", description: "INVALIDO"});
                } else {
                    setCommodity({code: data[0].codigo, description: data[0].descripcion});
                }
            } catch (error) {
                notifications.show({
                    title: "Error!",
                    message: "Error al buscar commodity.",
                    color: "red",
                });
            }
        }
    }

    return (
        <Container styleVariant={"unstyled"} direction={"row"} wrap={"wrap"} columnGap={8} align={"start"}>
            <Text lh={1.55} mb={1} w={"100%"} cVariant={"primary"}>Commodity *</Text>
            <Container styleVariant={"unstyled"} direction={"row"} wrap={"no-wrap"} columnGap={8} align={"center"}>
                <TextInput
                    name={"code"}
                    value={commodity?.code} onChange={handleChange}
                    onKeyDown={handleEventChange}
                    onBlur={handleEventChange}
                    style={{width: "30%"}}
                    error={error ?? false}
                    {...props}
                />
                <TextInput
                    value={commodity?.description}
                    style={{width: "60%"}}
                    disabled
                    error={error ?? false}
                    {...props}
                />
                <CommodityTable
                    setCommodity={setCommodity}
                />
            </Container>
            {error &&
                <Text fs-variant={"xxs"} fw={400} mt={"4px"} w={"100%"} cVariant={"error"}>{errorMsg}</Text>
            }
        </Container>
    )
}