import React, {useContext} from "react";
import Modal from 'react-bootstrap/Modal';
import {TRANSLATION_KEYS} from "../util/constants";
import {Textarea} from "@mantine/core";
import {LanguageContext} from "../context/LanguageContext";
import Button from "./base/Button/Button";
import Title from "./base/Title/Title";

const WorkNotes = ({updateStateValues, workNotes, onClose, readOnly = false}) => {
    const {t} = useContext(LanguageContext);

    return (
        <Modal size="lg" show={true} onHide={onClose} backdrop="static" keyboard={false} nested={0}>
            <Modal.Header closeButton style={{height: "50px"}}>
                <Title>{t(TRANSLATION_KEYS.NOTES)}</Title>
            </Modal.Header>
            <Modal.Body>
                <Textarea rows={8} defaultValue={workNotes} placeholder="Ingrese las notas de trabajo"
                          style={{resize: "none"}}
                          autoComplete="on" onChange={(e) => updateStateValues({notas: e.target.value})}
                          disabled={readOnly}/>
            </Modal.Body>
            <Modal.Footer>
                <Button id="save-close-button" onClick={() => onClose()}>
                    {t(TRANSLATION_KEYS.CLOSE)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default WorkNotes;