import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {ActionIcon} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTrash} from "@fortawesome/free-solid-svg-icons";
import {validateNumericInputWithComma} from "../util/functions";
import {notifications} from "@mantine/notifications";
import Title from "./base/Title/Title";
import TextInput from "./base/TextInput/TextInput";
import Select from "./base/Select/Select";
import Button from "./base/Button/Button";

const optionsFCL = [
    {key: '20FR', label: '20FR'},
    {key: '20GP', label: '20GP'},
    {key: '20HC', label: '20HC'},
    {key: '20NOR', label: '20NOR'},
    {key: '20OT', label: '20OT'},
    {key: '20PL', label: '20PL'},
    {key: '20RE', label: '20RE'},
    {key: '40FR', label: '40FR'},
    {key: '40GP', label: '40GP'},
    {key: '40HC', label: '40HC'},
    {key: '40NOR', label: '40NOR'},
    {key: '40OT', label: '40OT'},
    {key: '40PL', label: '40PL'},
    {key: '40RE', label: '40RE'},
    {key: '40REHC', label: '40REHC'},
    {key: '45HC', label: '45HC'}
];

const optionsFTL = [
    {key: 'OPEN', label: 'Semi'},
    {key: 'RTRK', label: 'Furgon / Cerrado'},
    {key: 'R1FT', label: 'Chata'},
    {key: 'REFR', label: 'Refrigerado'},
    ...optionsFCL
];

function ContainerTableModal({containers, updateStateValues, editable = true, type}) {
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [rows, setRows] = useState(containers || [{quantity: '', container: 'null', index: 0}]);

    function handleClose() {
        setShow(false);
    }

    function addRow() {
        setRows([...rows, {quantity: '', container: 'null', index: index + 1}]);
        setIndex(index + 1);
    }

    function deleteRow(index) {
        setRows(prevRows => {
            const newRows = [...prevRows];
            newRows.splice(index, 1);
            return newRows;
        });
    }

    function saveAndClose() {
        if (rows.length === 0) {
            notifications.show({
                title: "Error!",
                message: "Debe agregar al menos un contenedor.",
                color: "red",
            });
            return;
        }

        let hasEmptyValue = false;
        let data = [];
        for (const element of rows) {
            const quantity = element.quantity;
            const container = element.container;

            if (quantity === '' || container === 'null') {
                hasEmptyValue = true;
                break;
            }

            if (data.some(item => item.container === container)) {
                notifications.show({
                    title: "Error!",
                    message: "No puede agregar el mismo contenedor más de una vez.",
                    color: "red",
                });
                return;
            }

            data = data.concat({quantity, container});
        }

        if (hasEmptyValue) {
            notifications.show({
                title: "Error!",
                message: "Debe llenar todos los campos antes de guardar.",
                color: "red",
            });
            return;
        }

        let totalQuantity = 0;
        for (const element of rows) {
            const quantity = Number(element.quantity);

            if (quantity === 0) {
                notifications.show({
                    title: "Error!",
                    message: "La cantidad de contenedores no puede ser 0.",
                    color: "red",
                });
                return;
            }

            totalQuantity += quantity;
        }

        if (totalQuantity === 0) {
            notifications.show({
                title: "Error!",
                message: "Debe agregar al menos un contenedor.",
                color: "red",
            });
            return;
        }

        updateStateValues({containerQty: totalQuantity, containerData: data});

        handleClose();
    }

    useEffect(() => {
        if (containers) {
            if (containers.length > 0) {
                setRows(containers);
            } else {
                setRows([{quantity: '', container: 'null', index: 0}]);
            }
        }
    }, [containers]);

    return (
        <>
            <ActionIcon className={"mb-1"} size={"sm"} variant="filled" onClick={handleShow}>
                <FontAwesomeIcon style={{width: '70%', height: '70%'}} icon={faBars}/>
            </ActionIcon>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                nested={0}
            >
                <Modal.Header closeButton>
                    <Title>{type === 'FTL' ? 'Camiones' : 'Contenedores'}</Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"customTableContainerExtraPadding"}>
                        <table id="table-containers">
                            <thead>
                            <tr>
                                <th style={{width: "30%"}}>Cantidad</th>
                                <th>Tipo</th>
                                {editable &&
                                    <th/>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td>
                                        <TextInput disabled={!editable} value={row.quantity} id="input"
                                                   onChange={(event) => {
                                                       const newValue = validateNumericInputWithComma(event.target.value);
                                                       setRows(prevRows => {
                                                           const newRows = [...prevRows];
                                                           newRows[index] = {
                                                               ...newRows[index],
                                                               quantity: newValue,
                                                           };
                                                           return newRows;
                                                       });
                                                   }}
                                        />
                                    </td>
                                    <td>
                                        <Select disabled={!editable} value={row.container} id="select"
                                                onChange={(value) => {
                                                    setRows(prevRows => {
                                                        const newRows = [...prevRows];
                                                        newRows[index] = {
                                                            ...newRows[index],
                                                            container: value,
                                                        };
                                                        return newRows;
                                                    });
                                                }}
                                                data={
                                                    (type === 'FCL' &&
                                                        optionsFCL.map(option => ({
                                                                value: option.key,
                                                                label: option.label
                                                            }
                                                        )))
                                                    ||
                                                    (type === 'FTL' &&
                                                        optionsFTL.map(option => ({
                                                                value: option.key,
                                                                label: option.label
                                                            }
                                                        )))
                                                }
                                        />
                                    </td>
                                    {editable && (
                                        <td>
                                            <div>
                                                <ActionIcon color={"red"} onClick={() => deleteRow(index)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </ActionIcon>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {editable && (
                        <>
                            <Button bgVariant={"secondary"} id="add-row-button" onClick={addRow}>
                                Agregar {type === 'FTL' ? 'Camión' : 'Contenedor'}
                            </Button>
                            <Button id="save-close-button" onClick={() => saveAndClose()}>
                                Guardar y Cerrar
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ContainerTableModal;