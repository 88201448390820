import {Table as MantineTable} from "@mantine/core";
import {forwardRef} from "react";

const TData = forwardRef(({children, ...props}, ref) => {
    return (
        <MantineTable.Td ref={ref} {...props}>
            {children}
        </MantineTable.Td>
    )
})

export default TData;