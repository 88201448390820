import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {isStringEmpty, validateDate} from "../../util/functions";
import thirdPartyController from "../../controller/thirdPartyController";
import {notifications} from "@mantine/notifications";
import {COLORS, TRANSLATION_KEYS} from "../../util/constants";
import {Navbar} from "react-bootstrap";
import {ActionIcon, Indicator, rem, Stack, useMantineColorScheme} from "@mantine/core";
import PickupTable from "../../components/PickupTable";
import ContainerTable from "../../components/ContainerTable";
import UploadDocs from "../../components/UploadDocs";
import PackingTable from "../../components/PackingTable";
import EditableCostTable from "./components/EditableCostTable";
import {LanguageContext} from "../../context/LanguageContext";
import _ from "lodash";
import {modals} from "@mantine/modals";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import WorkNotes from "../../components/WorkNotes";
import TextInput from "../../components/base/TextInput/TextInput";
import Button from "../../components/base/Button/Button";
import Text from "../../components/base/Text/Text";
import Title from "../../components/base/Title/Title";
import Select from "../../components/base/Select/Select";
import Container from "../../components/base/Container/Container";
import {useIsFirstRender} from '@mantine/hooks';
import {LoadingContext} from "../../context/LoadingContext";
import darkThemeLogo from "../../assets/dark-theme-logo-min.png";
import lightThemeLogo from "../../assets/light-theme-logo-min.png";
import {IconMoon, IconSun} from "@tabler/icons-react";

function ThirdPartyNewCost() {
    const {encodedJwt} = useParams();
    const {setLoading} = useContext(LoadingContext);
    const [data, setData] = useState({
        quotationId: null,
        providerId: null,
        quotation: {},
        provider: {},
        chargeCodes: [],
        costRequest: {},
    });
    const isFirstRender = useIsFirstRender();
    const [pickupVisible, setPickupVisible] = useState(false);
    const [containerVisible, setContainerVisible] = useState(false);
    const [showUploadDocs, setShowUploadDocs] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [availableForQuote, setAvailableForQuote] = useState(true);
    const {language, setLanguage, t, languageOptions} = useContext(LanguageContext);
    const [cost, setCost] = useState([]);
    const [reference, setReference] = useState('');

    useEffect(() => {
        if (isFirstRender) {
            getQuotation();
        }
    }, []);

    function handleLanguageChange(value) {
        setLanguage(value);
        getQuotation(value);
    }

    async function getQuotation(languageValue = null) {
        if (encodedJwt === null || isStringEmpty(encodedJwt))
            return;

        setLoading(true);

        await thirdPartyController.getQuotationData(encodedJwt, languageValue)
            .then(response => {
                const quotation = {...response.quotation.data}

                if (quotation?.modo === 'FCL' || quotation?.modo === 'FTL') {
                    setContainerVisible(true);
                    quotation.containerQty = quotation?.containerData?.reduce(
                        (acc, container) => acc + parseFloat(container.quantity), 0
                    );
                }

                if (quotation?.pickupData.length > 0) {
                    setPickupVisible(true);
                }

                setData({
                    ...response,
                    quotation: quotation,
                });
                setLanguage(response.language);

                setAvailableForQuote(!response.costRequest?.events?.some(event => event.status === 'QUOTED'));
            })
            .catch(() =>
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.ERROR_WHILE_GETTING_QUOTATION),
                    color: "red",
                })
            )

        setLoading(false);
    }

    function handleSendClick() {
        modals.openContextModal({
            modal: "confirm",
            title: t(TRANSLATION_KEYS.SEND_COSTS_CONFIRMATION),
            innerProps: {
                onConfirm: sendCosts,
            }
        });
    }

    async function sendCosts() {
        let hasErrors = false;
        cost.every(cost => {
            if (cost.charges?.some(charge => !charge.concept)) {
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.ERROR_EVERY_CHARGE_NEEDS_A_CODE),
                    color: "red",
                });
                hasErrors = true;
                return false;
            }

            if (cost.charges?.every(charge => charge.amount === 0 || charge.amount === '')) {
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.ERROR_AT_LEAST_ONE_VALUE),
                    color: "red",
                });
                hasErrors = true;
                return false;
            }

            if (cost.validez !== '' && cost.validez !== null) {
                if (!validateDate(cost.validez)) {
                    notifications.show({
                        title: t(TRANSLATION_KEYS.ERROR_ALERT),
                        message: t(TRANSLATION_KEYS.ERROR_WRONG_DATE_FORMAT),
                        color: "red",
                    });
                    hasErrors = true;
                    return false;
                }
            }
            return true;
        });
        if (hasErrors) return;

        setLoading(true);
        await thirdPartyController.postQuotationCosts(encodedJwt, language, cost, reference)
            .then(() => {
                notifications.show({
                    title: t(TRANSLATION_KEYS.SUCCESS_ALERT),
                    message: t(TRANSLATION_KEYS.SEND_COSTS_SUCCESS),
                    color: "green",
                });
            })
            .catch(() =>
                notifications.show({
                    title: t(TRANSLATION_KEYS.ERROR_ALERT),
                    message: t(TRANSLATION_KEYS.ERROR_SENDING_COSTS),
                    color: "red",
                })
            ).then(() => setAvailableForQuote(false));

        setLoading(false);
    }

    return (
        <>
            {showUploadDocs && <UploadDocs onClose={() => setShowUploadDocs(false)} docs={data.quotation?.docs}
                                           editable={false}/>}
            {showNotes && <WorkNotes onClose={() => setShowNotes(false)} workNotes={data.quotation?.notas}
                                     readOnly={true}/>}

            <Navbar bg-variant={"surface-1"} brdr-variant={"default-bottom"} variant="dark"
                    fixed="top"
                    style={{padding: '8px', borderBottom: '5px solid white'}}>
                <Container styleVariant={"unstyled"} fluid>
                    <Navbar.Brand className="navbar-brand">
                        <img src={useMantineColorScheme().colorScheme === "dark" ? darkThemeLogo : lightThemeLogo}
                             alt="Logo"
                             className="navbar-logo"
                             style={{height: "35px", maxWidth: "auto", marginRight: "20px", marginLeft: "10px"}}
                        />
                    </Navbar.Brand>
                </Container>
                <div className={"d-flex flex-grow-1 justify-content-end gap-2"}>
                    <ActionIcon onClick={useMantineColorScheme().toggleColorScheme} bg={"none"}>
                        {
                            useMantineColorScheme().colorScheme === "dark" ?
                                <IconSun color={"var(--color-surface-3)"}/>
                                :
                                <IconMoon color={"var(--color-surface-3)"}/>
                        }
                    </ActionIcon>
                    <Select value={language} data={languageOptions}
                            onChange={(value) => handleLanguageChange(value)}/>
                </div>
            </Navbar>
            <Stack m={8} mt={rem(70)} mih={`calc(100vh - ${rem(80)})`} className={"default-container"}
                   bg-variant={"surface-1"} brdr-variant={"default-rounded"}
            >
                {data?.quotation?.active === 0 &&
                    <div
                        className={"d-flex flex-column gap-3 flex-grow-1 justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={faBan} color={COLORS.PRIMARY} size={"2xl"}/>
                        <Title order={4} cVariant={"primary"}>{t(TRANSLATION_KEYS.QUOTATION_HAS_BEEN_CANCELED)}</Title>
                    </div>
                }
                {!availableForQuote && data?.quotation?.active === 1 &&
                    <div
                        className={"d-flex flex-column gap-3 flex-grow-1 justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={faBan} color={COLORS.PRIMARY} size={"2xl"}/>
                        <Title order={4} cVariant={"primary"}>{t(TRANSLATION_KEYS.COST_ALREADY_QUOTED)}</Title>
                    </div>
                }
                {availableForQuote && data?.quotation?.active === 1 &&
                    <>
                        <div className="d-flex justify-content-center mt-3">
                            <Title order={3}
                                   cVariant={"primary"}>{data.quotation?.referencia} - {data.provider?.name}</Title>
                        </div>
                        <Container m={8} styleVariant={"unstyled"} className={"d-flex gap-2"}>
                            <Container pt={8} pb={8} className={"d-flex flex-column flex-grow-1"}
                                       style={{maxWidth: "40%"}}>
                                <div className="d-flex flex-wrap align-items-end">
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.ORIGIN)}
                                                   value={data.quotation?.origenName}
                                                   disabled/>
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.DESTINATION)}
                                                   value={data.quotation?.destinoName}
                                                   disabled/>
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.TRANSPORT)}
                                                   value={data.quotation?.transportMode}
                                                   disabled/>
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.MODE)} value={data.quotation?.modo}
                                                   disabled/>
                                    </div>
                                    {containerVisible ?
                                        <div
                                            className='mb-1 container-20 d-flex flex-row flex-wrap align-content-center column-gap-2'>
                                            <Text lh={1.55} mb={1} w={"100%"} cVariant={"primary"}>
                                                {data.quotation?.modo === 'FCL' ? t(TRANSLATION_KEYS.CONTAINERS) : t(TRANSLATION_KEYS.TRUCKS)}
                                            </Text>
                                            <TextInput w={"70%"} value={data.quotation?.containerQty} disabled/>
                                            <div className={"align-self-end"}>
                                                <ContainerTable containers={data.quotation?.containerData}
                                                                type={data.quotation?.modo}
                                                                editable={false}/>
                                            </div>
                                        </div>
                                        :
                                        <div className='mb-1 container-20'/>
                                    }
                                    {data.quotation?.modo === 'LCL' &&
                                        <div className='container-20'>
                                            <TextInput
                                                label={t(TRANSLATION_KEYS.STACKABLE)}
                                                value={data.quotation?.stackable ? t(TRANSLATION_KEYS.YES) : t(TRANSLATION_KEYS.NO)}
                                                disabled
                                            />
                                        </div>
                                    }
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.INCOTERM)}
                                                   value={data.quotation?.incoterm}
                                                   disabled/>
                                    </div>
                                    {pickupVisible && (
                                        <div className={"mb-1 container-20 d-flex flex-row gap-1"}>
                                            <TextInput label={t(TRANSLATION_KEYS.PICKUP)}
                                                       value={data.quotation?.pickupData?.length}
                                                       disabled/>
                                            <div className={"align-self-end"}>
                                                <PickupTable data={data.quotation?.pickupData} editable={false}/>
                                            </div>
                                        </div>
                                    )}
                                    <div className='mb-1 container-25'>
                                        <Text lh={1.55} mb={1} w={"100%"} cVariant={"primary"}>
                                            {t(TRANSLATION_KEYS.GOODS_VALUE)}
                                        </Text>
                                        <TextInput value={data.quotation?.valorMercaderia}
                                                   disabled={true}
                                                   rightSectionText={data.quotation?.monedaMercaderia}/>
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.COMMODITY)}
                                                   value={data.quotation?.commodityDescr}
                                                   disabled/>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                    <div
                                        className='mb-1 container-20 d-flex flex-row gap-2 align-items-end'>
                                        <TextInput label={t(TRANSLATION_KEYS.TOTAL_WEIGHT)}
                                                   value={data.quotation?.pesoTotal}
                                                   disabled name={"pesoTotal"}
                                                   rightSectionText={"KG"}
                                        />
                                    </div>
                                    <div
                                        className='mb-1 container-20 d-flex flex-row gap-2 align-items-end'>
                                        <TextInput label={t(TRANSLATION_KEYS.TOTAL_VOLUME)}
                                                   value={data.quotation?.cbmTotal}
                                                   disabled name={"cbmTotal"}
                                                   rightSectionText={"M3"}
                                        />
                                    </div>
                                    <div
                                        className='mb-1 container-20 d-flex flex-row gap-2 align-items-end'>
                                        <TextInput label={t(TRANSLATION_KEYS.TOTAL_CHARGEABLE)}
                                                   value={data.quotation?.chargeableTotal} disabled
                                                   name={"chargeableTotal"}
                                                   rightSectionText={"KG"}
                                        />
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.TOTAL_PALLETS)}
                                                   value={data.quotation?.pltTotal}
                                                   disabled name={"pltTotal"}
                                        />
                                    </div>
                                    <div className='mb-1 container-20'>
                                        <TextInput label={t(TRANSLATION_KEYS.TOTAL_CARTONS)}
                                                   value={data.quotation?.ctnTotal}
                                                   disabled name={"ctnTotal"}
                                        />
                                    </div>
                                </div>
                                <Container styleVariant={"unstyled"} p={8}
                                           className={"mt-2 d-flex justify-content-end"}>
                                    {data.quotation?.docs?.length > 0 &&
                                        <Indicator inline processing color="green"
                                                   disabled={data.quotation?.docs?.length === 0}>
                                            <Button onClick={() => setShowUploadDocs(true)}>
                                                {t(TRANSLATION_KEYS.VIEW_DOCS)}
                                            </Button>
                                        </Indicator>
                                    }
                                    {data.quotation?.notas &&
                                        <Indicator inline processing color="green"
                                                   disabled={data.quotation?.notas?.length === 0}>
                                            <Button onClick={() => setShowNotes(true)}>
                                                {t(TRANSLATION_KEYS.VIEW_NOTES)}
                                            </Button>
                                        </Indicator>
                                    }
                                </Container>
                                {data.quotation?.packingData?.length > 0 &&
                                    <div className={"mt-2 d-flex flex-column justify-content-center"}>
                                        <Title order={5} cVariant={"primary"} style={{textAlign: "center"}}>
                                            {t(TRANSLATION_KEYS.PACKING)}
                                        </Title>
                                        <PackingTable updateStateValues={() => {
                                        }}
                                                      quotation={data.quotation} inCW={true}/>
                                    </div>
                                }
                            </Container>
                            <Container p={8} className={"d-flex flex-column flex-grow-1"} style={{maxWidth: "60%"}}>
                                <EditableCostTable quotationId={data.quotationId}
                                                   transportMode={data.quotation?.transportMode}
                                                   mode={data.quotation?.modo}
                                                   chargeable={data.quotation?.chargeableTotal}
                                                   provider={data.provider}
                                                   chargeCodes={data.chargeCodes}
                                                   defaultChargeCodes={data.defaultChargeCodes}
                                                   data={cost}
                                                   setData={setCost}
                                                   reference={reference}
                                                   setReference={setReference}
                                />
                            </Container>
                        </Container>
                        <Container styleVariant={"unstyled"} mr={16} justify={"flex-end"}>
                            <Button onClick={_.debounce(handleSendClick, 1000, {leading: true, trailing: false})}>
                                {t(TRANSLATION_KEYS.SEND_COSTS)}
                            </Button>
                        </Container>
                    </>
                }
            </Stack>
        </>
    )
}

export default ThirdPartyNewCost;