import React, {useEffect} from 'react';
import {Link, RichTextEditor as MantineRichTextEditor} from '@mantine/tiptap';
import {useEditor} from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';

/**
 * @param {Object} props - The properties passed to the component
 * @param {string} props.fsVariant - The font size variant | default "xs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
 * @param {string} props.bgVariant - The background variant | default "surface-0" | "surface-0" | "surface-1" | "surface-2" | "surface-3" | "surface-4" |
 * @param {string} props.defaultContent - The default content of the editor
 * @param {string} props.content - The current content of the editor
 */
function RichTextEditor({fsVariant = "xs", bgVariant = "surface-0", defaultContent, content, setContent}) {
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Highlight,
            TextAlign.configure({types: ['heading', 'paragraph']}),
        ],
        content,
        editable: true,
        onUpdate({editor}) {
            setContent(editor.getHTML());
        },
    });

    useEffect(() => {
        editor?.commands?.setContent(defaultContent);
    }, [defaultContent]);


    return (
        <MantineRichTextEditor editor={editor}>
            <MantineRichTextEditor.Toolbar bg-variant={bgVariant} sticky stickyOffset={60}>
                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.Bold/>
                    <MantineRichTextEditor.Italic/>
                    <MantineRichTextEditor.Underline/>
                    <MantineRichTextEditor.Strikethrough/>
                    <MantineRichTextEditor.ClearFormatting/>
                    <MantineRichTextEditor.Highlight/>
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.H1/>
                    <MantineRichTextEditor.H2/>
                    <MantineRichTextEditor.H3/>
                    <MantineRichTextEditor.H4/>
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.BulletList/>
                    <MantineRichTextEditor.OrderedList/>
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.AlignLeft/>
                    <MantineRichTextEditor.AlignCenter/>
                    <MantineRichTextEditor.AlignJustify/>
                    <MantineRichTextEditor.AlignRight/>
                </MantineRichTextEditor.ControlsGroup>

                <MantineRichTextEditor.ControlsGroup>
                    <MantineRichTextEditor.Undo/>
                    <MantineRichTextEditor.Redo/>
                </MantineRichTextEditor.ControlsGroup>
            </MantineRichTextEditor.Toolbar>

            <MantineRichTextEditor.Content fs-variant={fsVariant} bg-variant={bgVariant}/>
        </MantineRichTextEditor>
    );
}

export default RichTextEditor;