import {Text as MantineText} from '@mantine/core';

/**
 * @description - Wrapper custom component for Mantine Text
 * @param cVariant - Color variant of the text | default: text | primary | secondary | tertiary | success | error | text | color-surface[0-4]
 * @param fw - Font weight of the text | default: 500
 * @param size - Size of the text | default: xs | xs | sm | md | lg | xl | xxl
 * */
export default function Text({cVariant = "text", fw = 500, size = "xs", ...props}) {
    return <MantineText c-variant={cVariant} size={size} fw={fw} {...props} />;
}