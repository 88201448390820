import httpsModule from '../util/http';
import {BASE_URL} from '../util/constants';

const SHIPMENT_EXPRESS_ENDPOINT = `${BASE_URL}/api/shipment/express/`;

const shipmentExpressController = {
    getShipments: async (status) => {
        try {
            const response = await httpsModule.get(`${SHIPMENT_EXPRESS_ENDPOINT}`, {params: {status}});
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getShipment: async (shipmentRef) => {
        try {
            const response = await httpsModule.get(`${SHIPMENT_EXPRESS_ENDPOINT}${shipmentRef}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    requoteShipment: async (shipmentRequoteRequest) => {
        try {
            const response = await httpsModule.put(`${SHIPMENT_EXPRESS_ENDPOINT}`, shipmentRequoteRequest);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    completeShipment: async (shipmentId) => {
        try {
            const response = await httpsModule.put(`${SHIPMENT_EXPRESS_ENDPOINT}complete/${shipmentId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateShipment: async (request) => {
        try {
            await httpsModule.post(`${SHIPMENT_EXPRESS_ENDPOINT}`, request);
            return 'SUCCESS';
        } catch (error) {
            throw error;
        }
    },

    getCartaFlete: async (reference) => {
        try {
            const response = await httpsModule.get(`${SHIPMENT_EXPRESS_ENDPOINT}carta-flete/${reference}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default shipmentExpressController;