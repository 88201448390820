import {Modal} from "react-bootstrap";
import Button from "./base/Button/Button";
import React, {useState} from "react";
import TextInput from "./base/TextInput/TextInput";
import Text from "./base/Text/Text";
import Title from "./base/Title/Title";
import Container from "./base/Container/Container";
import {addDraft, addExpressDraft} from "../util/functions";
import {DRAFT_TYPES} from "../util/constants";

export default function SaveDraftModal({onClose, onSuccess, data, type, selectedClient}) {
    const [draftName, setDraftName] = useState("");

    function handleSave() {
        if (type === DRAFT_TYPES.NEW_EXPRESS) {
            addExpressDraft(data, type, draftName, selectedClient);
        } else {
            addDraft(data, type, draftName);
        }

        setDraftName("");
        onSuccess();
    }

    return (
        <Modal show={true} onHide={onClose} backdrop="static" keyboard={false} scrollable={true} nested={0}>
            <Modal.Header>
                <Title>¿Guardar en borradores?</Title>
            </Modal.Header>
            <Modal.Body>
                <Container styleVariant={"unstyled"} direction={"column"} rowGap={8} p={8}>
                    <TextInput label="Nombre"
                               placeholder="Ingrese un nombre para identificar este borrador."
                               value={draftName.value}
                               onChange={(e) => setDraftName(e.target.value)}
                    />
                    <Text mt={8}>
                        Puede guardar los datos que ingresó hasta el momento como un borrador para
                        continuar luego. <br/>
                        Recuerde que los borradores no guardan los documentos adjuntados y
                        no se comparten entre dispositivos.
                    </Text>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button bgVariant={"secondary"} onClick={onClose}>
                    Cancelar
                </Button>
                <Button onClick={handleSave}>
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}