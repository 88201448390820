import {createContext, useEffect, useState} from "react";
import {STORAGE} from "../util/localStorage";
import {useTranslation} from "../util/localization";
import {SUPPORTED_LANGUAGES} from "../util/constants";

export const LanguageContext = createContext();

export const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState('');
    const {t} = useTranslation(language);

    useEffect(() => {
        setLanguage(STORAGE.get(STORAGE.LANGUAGE) || SUPPORTED_LANGUAGES.SPANISH);
    }, []);

    const provider = {
        language,
        setLanguage: (lang) => {
            STORAGE.set(STORAGE.LANGUAGE, lang);
            setLanguage(lang);
        },
        t,
        languageOptions: [
            {value: SUPPORTED_LANGUAGES.ENGLISH, label: 'EN - English'},
            {value: SUPPORTED_LANGUAGES.SPANISH, label: 'ES - Spanish'},
            {value: SUPPORTED_LANGUAGES.PORTUGUESE, label: 'PT - Portuguese'}
        ]
    };

    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
}