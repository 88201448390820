import {MenuDropdown as MantineMenuDropdown} from '@mantine/core';

function MenuDropdown({children, ...props}) {
    return (
        <MantineMenuDropdown {...props}>
            {children}
        </MantineMenuDropdown>
    )
}

export default MenuDropdown;