import axios from "axios";
import {useNavigate} from "react-router-dom";
import {STORAGE} from "./localStorage";
import {BASE_URL} from './constants';
import jwt_decode from "jwt-decode";
import {isTokenExpired} from "./functions";

const httpsModule = axios.create({
    BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${STORAGE.get(STORAGE.TOKEN)}`,
    },
});

httpsModule.interceptors.request.use(
    (config) => {
        const token = STORAGE.get(STORAGE.TOKEN);
        if (token) {
            const decoded = jwt_decode(token);
            if (isTokenExpired(decoded?.exp)) {
                const navigate = useNavigate();
                STORAGE.remove(STORAGE.TOKEN);
                navigate('/login');
                return Promise.reject(new Error("Token expired"));
            }
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default httpsModule;
