import {Table as MantineTable} from "@mantine/core"
import styles from "./Table.module.css"
import ContainerScrollableY from "../Container/ContainerScrollableY";

/**
 * @description Wrapper custom component for Mantine Button
 * @param mt {number} - margin-top default 8
 * @param mah {number} - max-height default 300
 * @param fsVariant {string} - font-size variant default "sm" | "xs" | "sm" | "md" | "lg" | "xl"
 */
export default function Table({mt = 8, mah = 300, fsVariant = "sm", fullWidth = null, children, ...props}) {
    return (
        <ContainerScrollableY w={fullWidth ? "100%" : null} mt={mt} mah={mah}>
            <MantineTable classNames={styles} {...props} highlightOnHover fs-variant={fsVariant}>
                {children}
            </MantineTable>
        </ContainerScrollableY>
    )
}