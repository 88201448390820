import httpsModule from "../util/http";
import {BASE_URL} from "../util/constants";

const QUOTATION_ENDPOINT = `${BASE_URL}/api/quotation/`;

const quotationController = {
    newQuotationCommercial: async (data) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}commercial`, data);
        return response.data;
    },

    newQuotationPricing: async (data, sendEmail) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}pricing`, data, {
            params: {sendEmail},
        });
        return response.data;
    },

    sendProviderRequest: async (data, sendEmail) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}providers/request`, data, {
            params: {sendEmail},
        });
        return response.data;
    },

    getQuotations: async (filter) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}find`, filter);
        return response.data;
    },

    getQuotationsManager: async (filter) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}find/manager`, filter);
        return response.data;
    },

    getMyQuotations: async (filter) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}find/my-quotations`, filter);
        return response.data;
    },

    getQuotationsAssigned: async (filter) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}find/assigned`, filter);
        return response.data;
    },

    getQuotation: async (reference) => {
        const response = await httpsModule.get(`${QUOTATION_ENDPOINT}${reference}`);
        return response.data;
    },

    updateQuotation: async (quotation, toCW1) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}update`, quotation, {
            params: {toCW1},
        });
        return response.data;
    },

    getQuotationPDF: async (quotation) => {
        const response = await httpsModule.post(`${QUOTATION_ENDPOINT}pdf`, quotation);
        return response.data;
    },

    finalizeQuote: async (data) => {
        const response = await httpsModule.put(`${QUOTATION_ENDPOINT}finalize`, data);
        return response.data;
    },

    notifyCommercial: async (reference, commercial) => {
        await httpsModule.get(`${QUOTATION_ENDPOINT}notify-commercial`, {
            params: {
                reference: reference,
                commercial: commercial
            },
        });
        return 'NOTIFICATION SENT';
    },

    notifyPricing: async (quotationId) => {
        await httpsModule.get(`${QUOTATION_ENDPOINT}notify-pricing/${quotationId}`);
        return 'NOTIFICATION SENT';
    },

    quotationRead: async (quotationId) => {
        await httpsModule.get(`${QUOTATION_ENDPOINT}read/${quotationId}`);
        return 'STATUS UPDATED';
    },

    saveQuotationCost: async (data) => {
        const response = await httpsModule.put(`${QUOTATION_ENDPOINT}save/cost`, data);
        return response.data;
    },

    addProviders: async (data) => {
        const response = await httpsModule.put(`${QUOTATION_ENDPOINT}providers`, data);
        return response.data;
    },

    deleteQuotation: async (idCotizacion) => {
        await httpsModule.delete(`${QUOTATION_ENDPOINT}${idCotizacion}`);
    },

    autoRate: async (referencia, totals) => {
        const response = await httpsModule.get(`${QUOTATION_ENDPOINT}auto-rate/${referencia}`, {
            params: {totals},
        });
        return response.data;
    },

    deleteQuotationCost: async (costId) => {
        await httpsModule.delete(`${QUOTATION_ENDPOINT}cost/${costId}`);
    },

    openQuotation: async (quotationId) => {
        await httpsModule.put(`${QUOTATION_ENDPOINT}open/${quotationId}`);
    },

    getNotes: async (reference) => {
        const response = await httpsModule.get(`${QUOTATION_ENDPOINT}notes/${reference}`);
        return response.data;
    },

    validate: async (reference) => {
        const response = await httpsModule.get(`${QUOTATION_ENDPOINT}validate/${reference}`);
        return response.data;
    }
};


export default quotationController;