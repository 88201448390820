import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {cleanString, isFileSizeValid, viewDocument} from '../util/functions';
import {ActionIcon, FileButton, Group} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import {notifications} from "@mantine/notifications";
import Select from "./base/Select/Select";
import Button from "./base/Button/Button";
import Title from "./base/Title/Title";
import Text from "./base/Text/Text";
import Dropzone from "./base/Dropzone/Dropzone";
import {MAX_UPLOAD_SIZE, QUOTATION_DOCUMENT_TYPES} from "../util/constants";

const UploadDocs = ({onClose, docs, updateStateValues, editable = true}) => {
    const [documents, setDocuments] = useState(docs || []);

    async function handleUpload(files) {
        if (!files || files?.length === 0) {
            return;
        }

        const fileList = files;
        const newDocuments = [];

        if (fileList.some((element) => !isFileSizeValid(element?.size))) {
            notifications.show({
                title: "Error!",
                message: `Error al cargar los archivos. Se encontraron archivos que superan el tamaño maximo: ${MAX_UPLOAD_SIZE.label}`,
                color: "red",
            });
            return;
        }

        for (const element of fileList) {
            const file = element;
            const base64Data = await convertFileToBase64(file);
            newDocuments.push({
                nombre: cleanString(file.name),
                tipo: '',
                file: base64Data,
            });
        }

        setDocuments((prevDocuments) => [...prevDocuments, ...newDocuments]);
        updateStateValues({docs: [...newDocuments]});
    }

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    function handleTypeChange(value, index) {
        setDocuments((prevDocuments) => {
            const updatedDocuments = [...prevDocuments];
            updatedDocuments[index].tipo = value;
            return updatedDocuments;
        });
        updateStateValues({docs: [...documents]});
    }

    function handleDelete(index) {
        const newDocs = [...documents];
        newDocs.splice(index, 1);
        setDocuments(newDocs);

        updateStateValues({docs: [...newDocs]});
    }

    function handleClose() {
        const hasEmptyType = documents.some((document) => document.tipo === '');
        if (!hasEmptyType) {
            onClose();
        } else {
            notifications.show({
                title: "Error!",
                message: "Todos los documentos deben tener un tipo seleccionado.",
                color: "red",
            });
        }
    }

    return (
        <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} nested={0}>
            <Modal.Header closeButton>
                <Title>Documentos</Title>
            </Modal.Header>
            <Modal.Body>
                {editable && <Dropzone onDrop={(files) => handleUpload(files)}/>}
                {documents && documents.length > 0 && (
                    <div className={"customTableContainerExtraPadding"}>
                        <table>
                            <thead>
                            <tr>
                                <th>Documento</th>
                                <th>Tipo</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {documents.map((document, index) => (
                                <tr key={index}>
                                    <td style={{
                                        whiteSpace: 'normal',
                                        wordWrap: 'break-word',
                                        width: '50%',
                                        alignContent: 'center',
                                    }}>
                                        <Text style={{wordBreak: 'break-all'}}>
                                            {document.nombre}
                                        </Text>
                                    </td>
                                    <td style={{width: '30%'}}>
                                        <Select value={document.tipo}
                                                onChange={(value) => handleTypeChange(value, index)}
                                                data={QUOTATION_DOCUMENT_TYPES}
                                                placeholder={"-- seleccione --"}
                                                disabled={!editable}
                                        />
                                    </td>
                                    <td>
                                        <div className={"d-flex flex-row gap-3"}>
                                            <ActionIcon hbg-variant={"primary"} color={"blue"}
                                                        onClick={() => viewDocument(document)}>
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                            </ActionIcon>
                                            {editable && (
                                                <ActionIcon color={"red"} onClick={() => handleDelete(index)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </ActionIcon>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end gap-2">
                    {editable && (
                        <Group justify="center">
                            <FileButton multiple onChange={handleUpload}>
                                {(props) => <Button bgVariant={"secondary"} {...props}>Agregar Documento</Button>}
                            </FileButton>
                        </Group>
                    )}
                    <Button id="save-close-button" onClick={handleClose}>
                        {editable ?
                            'Guardar y Cerrar'
                            :
                            'Cerrar'
                        }
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadDocs;