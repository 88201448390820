import {MenuItem as MantineMenuItem} from '@mantine/core';

function MenuItem({children, ...props}) {
    return (
        <MantineMenuItem fs-variant={"xs"} p-variant={"4"} {...props} size={"xs"}>
                         {children}
        </MantineMenuItem>
    )
}

export default MenuItem;