import React, {useContext, useEffect, useState} from 'react';
import organizationController from '../controller/organizationController';
import {TextInput} from "@mantine/core";
import OrganizationAsyncSelect from "./async_select/OrganizationAsyncSelect";
import Container from "./base/Container/Container";
import Title from "./base/Title/Title";
import {CLIENT_INITIAL_STATE} from "../models/models";
import CountryAsyncInput from "./async_input/CountryAsyncInput";
import {LoadingContext} from "../context/LoadingContext";


function ClientPanel({updateClientValues, preClient, inCW}) {
    const {setLoading} = useContext(LoadingContext);
    const [clientEditable, setClientEditable] = useState(false);
    const [cliente, setCliente] = useState(preClient || CLIENT_INITIAL_STATE);
    const [selectedClient, setSelectedClient] = useState({id: 0, nombreFantasia: '', newClient: false});
    const [selectedCountry, setSelectedCountry] = useState({code: preClient.paisCod, name: preClient.paisNombre});
    useEffect(() => {
        setSelectedClient({id: preClient?.id, nombreFantasia: preClient?.nombreFantasia, newClient: false})
    }, []);

    useEffect(() => {
        if (!inCW) {
            updateClientValues(cliente);
        }
    }, [cliente]);

    useEffect(() => {
        if (selectedClient?.id !== 0) {
            handleClientSelection(selectedClient);
        }
    }, [selectedClient]);

    useEffect(() => {
        if (selectedCountry) {
            setCliente((prevState) => ({
                ...prevState,
                paisCod: selectedCountry.code,
                paisNombre: selectedCountry.name,
            }));
        }
    }, [selectedCountry]);

    function handleClienteChange(event) {
        const {name, value} = event.target;
        setCliente((prevCliente) => ({
            ...prevCliente,
            [name]: value,
        }));
    }

    function clienteValues(values) {
        setCliente(prevState => ({
            ...prevState,
            ...values,
        }));
    }

    const handleClientSelection = (client) => {
        const organizationId = client.id;

        if (client.newClient) {
            setCliente(() => ({
                ...CLIENT_INITIAL_STATE,
                nombreFantasia: client.nombreFantasia,
                nuevoCliente: 1,
            }));
            setSelectedCountry({code: '', name: ''})
            setClientEditable(true);
        } else {
            if (!organizationId || organizationId === 1) return;

            setLoading(true);
            organizationController.getClient(organizationId)
                .then((response) => {
                    clienteValues(response);
                    setSelectedCountry({code: response.paisCod, name: response.paisNombre})
                })
                .catch(() => {
                    console.error('Error fetching client data');
                });
            setClientEditable(false);
            setLoading(false);
        }
    };

    return (
        <Container direction={"column"} wrap={"wrap"} p={8}>
            <Title order={4} cVariant={"primary"} ta={"center"} w={"100%"}>CLIENTE</Title>
            <div className={"mb-1 w-100"}>
                <OrganizationAsyncSelect label={"Nombre"} size={"xs"} defaultValue={cliente.nombreFantasia}
                                         disabled={inCW} organization={selectedClient} setOrganization={setSelectedClient}/>
            </div>
            <div className={"mb-1 w-100"}>
                <TextInput label={"Razón Social"} name={"razonSocial"} value={cliente.razonSocial} size={"xs"}
                           disabled={inCW || !clientEditable} onChange={handleClienteChange}
                />
            </div>
            <div className={"mb-1 d-flex gap-2 w-100"}>
                <div className={"w-50"}>
                    <TextInput label={"RUT/CUIT"} name={"rut"} value={cliente.rut} size={"xs"}
                               disabled={inCW || !clientEditable} onChange={handleClienteChange}
                    />
                </div>
                <div className={"w-50"}>
                    <TextInput label={"Mail"} name={"mail"} value={cliente.mail} size={"xs"}
                               disabled={inCW || !clientEditable} onChange={handleClienteChange}
                    />
                </div>
            </div>
            <div className={"mb-1 d-flex gap-2 w-100"}>
                <div className={"w-50"}>
                    <TextInput label={"Dirección"} name={"direccion"} value={cliente.direccion} size={"xs"}
                               disabled={inCW || !clientEditable} onChange={handleClienteChange}
                    />
                </div>
                <div className={"w-50"}>
                    <TextInput label={"Código Postal"} name={"codPostal"} value={cliente.codPostal} size={"xs"}
                               disabled={inCW || !clientEditable} onChange={handleClienteChange}
                    />
                </div>
            </div>
            <div className={"mb-1 d-flex gap-2"}>
                <div className={"w-50"}>
                    <TextInput label={"Ciudad"} name={"ciudad"} value={cliente.ciudad} size={"xs"}
                               disabled={inCW || !clientEditable} onChange={handleClienteChange}
                    />
                </div>
                <div className={"w-50 d-flex flex-row gap-1 align-items-end"}>
                    <CountryAsyncInput country={selectedCountry}
                                       setCountry={setSelectedCountry}
                                       inputDisabled={inCW || !clientEditable}
                                       showTable={!(inCW || !clientEditable)}/>
                </div>
            </div>
        </Container>
    );
}

export default ClientPanel;