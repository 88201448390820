import React, {useContext, useEffect, useMemo, useState} from "react";
import {useParams} from 'react-router-dom';
import {MantineReactTable, useMantineReactTable} from 'mantine-react-table';
import {ActionIcon} from '@mantine/core';
import {IconRefresh} from '@tabler/icons-react';
import fontAwesomeIcons from '../../assets/FontAwesomeIcons';
import shipmentExpressController from "../../controller/shipmentExpressController";
import OperacionExpress from "./OperacionExpress";
import classes from "../MantineTable.module.css";
import {notifications} from "@mantine/notifications";
import {LoadingContext} from "../../context/LoadingContext";

function Operaciones() {
    const {setLoading} = useContext(LoadingContext);
    let queryStatus;
    const {status} = useParams();
    const [shipmentModal, setShipmentModal] = useState({show: false, reference: ""});
    const [shipmentData, setShipmentData] = useState([]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'commercial',
                header: 'Comercial',
                size: 115,
            },
            {
                accessorKey: 'reference',
                header: 'Referencia',
                enableClickToCopy: true,
                size: 150,
            },
            {
                accessorKey: 'cargoWiseShipmentKey',
                accessorFn: (row) => row.cargoWiseShipmentKey ?? "",
                header: 'Shipment CW1',
                enableClickToCopy: true,
                size: 150,
            },
            {
                accessorKey: 'waybill',
                accessorFn: (row) => row.waybill ?? "",
                header: 'Guía',
                enableClickToCopy: true,
                size: 150,
            },
            {
                accessorKey: 'date',
                header: 'Fecha',
                Cell: ({cell}) => cell.getValue().split(" ")[0],
            },
            {
                accessorKey: 'client.nombreFantasia',
                header: 'Cliente',
                size: 300,
            },
            {
                accessorKey: 'originCountry',
                header: 'Origen',
                size: 150,
            },
            {
                accessorKey: 'destinationCountry',
                header: 'Destino',
                size: 150,
            },
            {
                accessorKey: 'totalVolume',
                header: 'Volumen',
                size: 115,
                Cell: ({cell}) => cell.getValue() + ' M3',
            },
            {
                accessorKey: 'totalChargeableWeight',
                header: 'Chargeable',
                size: 125,
                Cell: ({cell}) => cell.getValue() + ' KG',
            },
        ],
        [],
    );
    const table = useMantineReactTable({
        columns,
        data: shipmentData,
        icons: fontAwesomeIcons,
        initialState: {density: 'xs', showGlobalFilter: true},
        defaultColumn: {minSize: 5, maxSize: 300, size: 100},
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableBottomToolbar: false,
        enablePagination: false,
        enableRowVirtualization: true,
        enableColumnActions: false,
        rowVirtualizerProps: {overscan: 30},
        mantineTopToolbarProps: {className: classes.topToolbar},
        mantinePaperProps: {className: classes.root},
        mantineTableContainerProps: {className: classes.tableContainer},
        mantineFilterTextInputProps: {className: classes.filterTextInput},
        mantineFilterSelectProps: {className: classes.filterSelect},
        mantineTableBodyRowProps: ({row}) => ({
            onDoubleClick: () => setShipmentModal({show: true, reference: row.original.reference}),
        }),
        mantineTableHeadProps: {className: classes.tableHead},
        mantineTableHeadCellProps: {align: 'center', className: classes.tableHeadCell},
        mantineTableBodyCellProps: {align: 'center'},
        mantineCopyButtonProps: {className: classes.copyButton},
        renderTopToolbarCustomActions: () => (
            <ActionIcon className={classes.buttons} onClick={() => findShipments()}>
                <IconRefresh c-variant={"primary"}/>
            </ActionIcon>
        ),
    });

    useEffect(() => {
        findShipments();
    }, [status]);

    async function findShipments() {
        setLoading(true);

        switch (status) {
            case "en-proceso":
                queryStatus = "IN_PROCESS";
                break;
            case "finalizadas":
                queryStatus = "COMPLETED";
                break;
        }

        await shipmentExpressController.getShipments(queryStatus)
            .then(data => {
                setShipmentData(data);
            })
            .catch(() => {
                notifications.show({
                    title: "Error!",
                    message: "Error al obtener operaciones.",
                    color: "red",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            {shipmentModal.show && <OperacionExpress reference={shipmentModal.reference}
                                                     handleClose={() => setShipmentModal({show: false, reference: ""})}
            />}
            <MantineReactTable table={table}/>
        </>
    );
}

export default Operaciones;